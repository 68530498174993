<template>
  <div id="rosechart-d3-container" class="w-full min-w-96 min-h-96 relative flex gap-8 box-border justify-center" :class="{ 'h-full': options.fullHeight }">
    <div :id="chartUid" class="box-border h-full relative" :style="`width: ${chartWidth}px; height: ${chartHeight}px`"></div>
  </div>
</template>

<script>
// to do: https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Optimizing_canvas

// import debounce from 'lodash/debounce'
import { createRoseChartD3 } from '../../composables/roseChart/createRoseChartD3.js'

export default {
  props: {
    chartId: {
      type: String,
      required: true,
    },
    categories: {
      type: Array,
      default: () => [],
    },
    datasets: {
      type: Array,
      default: () => [],
    },
    maxValue: {
      type: Number,
      default: 100,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      chart: null,
      chartWidth: 300,
      chartHeight: 300,
      chartUid: 'petal-chart-canvas-' + this.chartId,
    }
  },
  emits: ['update', 'rating'],
  watch: {
    datasets: {
      handler (newVal) {
        if (this.chart) {
          this.chart.updateDataValues(newVal)
        }
      },
      deep: true,
    },
  },
  methods: {
    async initChart () {
      const chartOptions = {
        ...this.options,
        width: this.chartWidth,
        height: this.chartHeight,
      }
      this.chart = createRoseChartD3(this.chartUid, this.categories, this.datasets, chartOptions)
      this.chart.on('rating', async (newValue, categoryIndex) => {
        this.$emit('rating', newValue, categoryIndex)
        this.chart.updateDataValues(this.datasets)
      })
    },
    destroyChart () {},
    updateChartData () {},
    getChartContainerHeight () {
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          const container = document.querySelector('#rosechart-d3-container')
          if (container) {
            const height = container.clientHeight
            resolve(height)
          } else {
            reject(new Error('Ambition chart container not found'))
          }
        })
      })
    },
    getChartContainerWidth () {
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          const container = document.querySelector('#rosechart-d3-container')
          if (container) {
            const width = container.clientWidth
            resolve(width)
          } else {
            reject(new Error('Rose chart container not found'))
          }
        })
      })
    },
  },
  async mounted () {
    if (this.options.chartWidth && this.options.chartHeight) {
      this.chartWidth = this.options.chartWidth
      this.chartHeight = this.options.chartHeight
    } else {
      const width = await this.getChartContainerWidth()
      const height = await this.getChartContainerHeight()
      this.chartWidth = width < height ? width : height
      this.chartHeight = width < height ? width : height
    }
    this.$nextTick(() => {
      this.initChart(true)
      this.chart.resize()
    })
  },
  beforeUnmount () {
    this.destroyChart()
  },
}
</script>

<style scoped>
</style>
