import feathersClient, { makeServicePlugin, BaseModel } from '../../feathers-client'

// Define the Organization model
class OrganizationsFrameworks extends BaseModel {
  static modelName = 'OrganizationsFrameworks'
  static instanceDefaults () {
    return {
      organizationId: null,
      frameworkId: null,
    }
  }
}
// Create the service plugin
const servicePath = 'organizations-frameworks'
const servicePlugin = makeServicePlugin({
  Model: OrganizationsFrameworks,
  service: feathersClient.service(servicePath),
  servicePath,
})
export default servicePlugin
