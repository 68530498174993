import { createStore, createLogger } from 'vuex'
import { actions } from './actions'
import { goals } from './goals'
import auth from './store.auth'
import { frameworkSettings, frameworkLayerSources } from '@/data'
import { calcCategoryQuestionRating } from '@/composables/ratings/calcCategoryQuestionRating'
import { getCategoryUserRatings } from '@/composables/ratings/getCategoryUserRating'
import { getCategoryActionRatings } from '@/composables/ratings/getCategoryActionRating'
import { getCategoryQuestionObjectiveRatings } from '@/composables/ratings/getCategoryQuestionObjectiveRating'
import { getCategoryQuestionSubjectiveRatings } from '@/composables/ratings/getCategoryQuestionSubjectiveRating'

/*
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
  key: 'kompass-vuex',
  storage: window.localStorage,
  reducer: (state) => ({
    users: state.users,
    userSettings: state.userSettings,
    cmsLocale: state.cmsLocale,
    auth: state.auth,
  }),
})
*/
const disableLogger = true

const requireModule = require.context(
  // The path where the service modules live
  './services',
  // Whether to look in subfolders
  false,
  // Only include .js files (prevents duplicate imports`)
  /\.js$/,
)
const servicePlugins = requireModule
  .keys()
  .map(modulePath => requireModule(modulePath).default)

const store = createStore({
  state: {
    userSettings: {
      tooltips: false,
      activeSubCategories: [],
      disableSave: true,
      workshopSteps: [],
    },
    cmsLocale: 'no_NO',
    currentProject: {
      title: '',
      compassId: null,
    },
    activeFrameworks: [],
    urls: {
      appApi: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API : window.location.origin,
      appWeb: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_WEB : window.location.origin,
      appApiRest: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_REST : `${window.location.origin}/api`,
    },
  },
  mutations: {
    setWorkshopSteps (state, steps) {
      state.userSettings.workshopSteps = steps
    },
    setCmsLocale (state, value) {
      state.cmsLocale = value
      // Set in local storage
      localStorage.setItem('user-lang', value)
    },
    toggleDisableSave (state, value) {
      state.userSettings.disableSave = value
    },
    toggleTooltips (state) {
      state.userSettings.tooltips = !state.userSettings.tooltips
    },
    toggleSubCategoryActive (state, id) {
      const subCats = state.userSettings.activeSubCategories
      const i = subCats.findIndex(subId => subId === id)
      if (i !== -1) {
        subCats.splice(i, 1)
      } else {
        subCats.push(id)
      }
    },
    setProjectTitle (state, title) {
      state.currentProject.title = title
    },
    setActiveFrameworks (state, frameworks) {
      state.activeFrameworks = frameworks
    },
    setFrameworkDataset (state, payload) {
      const { frameworkId, dataSource, values } = payload
      const framework = state.activeFrameworks.find(framework => framework.id === frameworkId)
      const dataset = framework.datasets.find(dataset => dataset.dataSource === dataSource)
      if (dataset) {
        dataset.values = values
      } else {
        console.log('NO DATASET FOUND', frameworkId, dataSource)
      }
    },
  },
  actions: {
    async rateQuestion ({ commit, dispatch }, payload) {
      const { compassQuestionId, voteTypeId, categoryId, value, compassId } = payload
      const answer = await dispatch('compass-question-answers/find', { query: { compassQuestionId } })
      if (Array.isArray(answer.data) && answer.data.length > 0) {
        await dispatch('compass-question-answers/patch', [answer.data[0].id, { value }])
      } else {
        await dispatch('compass-question-answers/create', { compassQuestionId, voteTypeId, categoryId, value, compassId })
      }
    },
    async setCmsLocale ({ commit, dispatch }, language) {
      commit('setCmsLocale', language)
      dispatch('refreshCatsAndSubCats')
      dispatch('refreshStandardActions')
    },
    async refreshCatsAndSubCats ({ commit, dispatch, state, getters }) {
      try {
        if (!state.auth.user) {
          return
        }
        const params = {
          query: {
            $limit: 99999,
            locale: state.cmsLocale,
          },
        }
        const calls = [
          dispatch('frameworks/find', { query: { id: { $in: state.auth.user.frameworkIds } } }),
          dispatch('frameworks-translations/find', { query: { frameworkId: { $in: state.auth.user.frameworkIds } } }),
          dispatch('action-collections/find', { query: { frameworkId: { $in: state.auth.user.frameworkIds } } }),
          dispatch('question-collections/find', { query: { frameworkId: { $in: state.auth.user.frameworkIds } } }),
          dispatch('categories/find', params),
          dispatch('vote-types/find', params),
          dispatch('vote-type-options/find', params),
          dispatch('sub-categories/find', {
            query: {
              ...params.query,
              localeRequired: false,
            },
          }),
          dispatch('sub-categories-defaults/find', {}),
        ]
        await Promise.all(calls)
        console.log('REFRESH CATS AND SUBCATS')
        console.log('FRAMEWORKS', getters['frameworks/list'])
        console.log('ACTION COLLECTIONS', getters['action-collections/list'])
        console.log('QUESTION COLLECTIONS', getters['question-collections/list'])
        console.log('_______________________')
      } catch (error) {
        console.log(error)
        throw new Error(error)
      }
    },
    async refreshStandardActions ({ dispatch, commit, state }, payload) {
      if (!store.getters['auth/isAuthenticated']) {
        return
      }
      try {
        commit('standard-actions/clearAll')
        commit('tags/clearAll')
        const query = {
          $limit: 10000,
          locale: state.cmsLocale,
          localeRequired: false,
        }
        if (payload?.countryId) {
          query.countryId = payload.countryId
        }
        if (payload?.isEnabled !== undefined) {
          query.isEnabled = payload.isEnabled
        }
        await dispatch('standard-actions/find', { query })
        await dispatch('standard-action-ratings/find')
        await dispatch('tags/find', { query: { locale: state.cmsLocale } })
      } catch (error) {
        console.log(error)
        throw new Error(error)
      }
    },
    async loadAppData ({ dispatch, commit, state }) {
      // THIS SHOULD BE CALLED ON APP LOAD
      // IT WILL LOAD:
      // - All frameworks that user is allowed to access
      // - All texts for the currently selected locale
    },
    async loadFrameworkData ({ dispatch, commit, state }, frameworkId) {
      // THIS IS CALLED WHEN A NEW FRAMEWORK IS SELECTED OR ON APP LOAD
      // IT WILL LOAD:
      // - All categories within the currently selected framework
      // - All sub-categories within the currently selected framework
      // - All standard-actions within the currently selected framework
      // - All standard-action-ratings within the currently selected framework
      // - All tags within the currently selected framework
    },
    async loadCompassData ({ dispatch, commit, state, getters }, ids) {
      if (ids.length === 0) {
        return
      }
      try {
        console.log('LOADING COMPASS DATA', ids)
        await dispatch('compasses/find')
        commit('compass-sub-categories/clearAll')
        commit('compass-actions/clearAll')
        commit('compass-question-answers/clearAll')
        commit('compass-categories-ratings/clearAll')
        commit('compass-user-ratings/clearAll')
        commit('compass-goals/clearAll')
        commit('board-notes/clearAll')
        commit('board-notes-content/clearAll')
        commit('compass-questions/clearAll')
        commit('compasses-frameworks/clearAll')

        // const compassId = ids.length > 1 ? { $in: state.compasses.ids } : Number(ids[0])
        const compassId = ids[0]
        if (typeof compassId !== 'number') {
          console.error('Compass ID is not a number:', compassId)
        }
        const calls = [
          dispatch('compass-sub-categories/find', { query: { compassId, locale: state.cmsLocale } }),
          dispatch('compass-actions/find', { query: { compassId } }),
          dispatch('compass-questions/find', { query: { compassId } }),
          dispatch('compass-user-ratings/find', { query: { compassId } }),
          dispatch('compass-goals/find', { query: { compassId } }),
          dispatch('board-notes/find', { query: { compassId } }),
          dispatch('board-notes-content/find', { query: { compassId } }),
          dispatch('compasses-frameworks/find', { query: { compassId } }),
          dispatch('compass-categories-ratings/find', { query: { compassId } }),
          ids.length === 1 ? dispatch('compass-workshop-steps/find', { query: { compassId } }) : Promise.resolve(),
        ]
        // Todo: load framework-settings and layer-sources and framework-layer-sources
        await Promise.all(calls)
        await dispatch('compass-question-answers/find', { query: { compassQuestionId: { $in: state['compass-questions'].ids } } })
        await dispatch('action-ratings/find', { query: { compassActionId: { $in: state['compass-actions'].ids } } })
        await dispatch('loadActiveFrameworks', compassId)
        return Promise.resolve()
      } catch (error) {
        console.log('ERROR LOADING COMPASS DATA', error)
        throw new Error(error)
      }
    },
    async loadActiveFrameworks ({ dispatch, commit, state }, compassId) {
      // TODO: Connect to backend
      const activeFrameworks = await dispatch('getActiveFrameworks', compassId)
      commit('setActiveFrameworks', activeFrameworks)
      console.log('ACTIVE FRAMEWORKS in store', state.activeFrameworks)
    },
    async reloadActiveFrameworkDatasets ({ dispatch, commit, state }, compassId) {
      const activeFrameworks = state.activeFrameworks
      for (let i = 0; i < activeFrameworks.length; i++) {
        for (let j = 0; j < activeFrameworks[i].datasets.length; j++) {
          const frameworkId = activeFrameworks[i].id
          const dataSource = activeFrameworks[i].datasets[j].dataSource
          await dispatch('loadFrameworkDataset', { frameworkId, compassId, dataSource })
        }
      }
    },
    async loadFrameworkDataset ({ dispatch, commit, state }, payload) {
      const { frameworkId, compassId, dataSource } = payload
      const values = await dispatch('calculateFrameworkDataset', { compassId, frameworkId, dataSource })
      console.log('VALUES', values)
      commit('setFrameworkDataset', { frameworkId, dataSource, values })
    },
    async getFrameworkDatasets ({ dispatch }, payload) {
      const { compassId, frameworkId } = payload
      /** frameworkLayerSources is a list of layers for a framework
       * [{
       *    id: 1,
       *    frameworkId: 1,
       *    label: 'Vision',
       *    dataSource: 'ambition-test',
       *    opacity: 1,
       *    roundToStep: false,
       * }]
       * TODO: get from db instead of data.js
      */
      const allLayers = frameworkLayerSources
      const frameworkLayers = allLayers.filter(layer => layer.frameworkId === frameworkId)
      for (let i = 0; i < frameworkLayers.length; i++) {
        const values = await dispatch('calculateFrameworkDataset', { compassId, frameworkId, dataSource: frameworkLayers[i].dataSource })
        frameworkLayers[i].values = values
      }
      return frameworkLayers
    },
    async calculateFrameworkDataset ({ getters }, payload) {
      const { compassId, frameworkId, dataSource } = payload
      const categories = getters.frameworkCategories(frameworkId)
      const compass = getters['compasses/get'](compassId)
      const compassVersion = compass.version
      // Validate required parameters
      if (!compassId || (typeof compassId !== 'string' && typeof compassId !== 'number')) {
        console.error('Invalid compassId:', compassId)
        return categories.map(() => 0)
      }

      if (!frameworkId || typeof frameworkId !== 'number') {
        console.error('Invalid frameworkId:', frameworkId)
        return categories.map(() => 0)
      }

      if (!dataSource || typeof dataSource !== 'string') {
        console.error('Invalid dataSource:', dataSource)
        return categories.map(() => 0)
      }

      if (!categories || !Array.isArray(categories) || categories.length === 0) {
        console.error('Invalid categories:', categories)
        return []
      }

      if (!compassVersion) {
        console.warn('compassVersion not provided, defaulting to 2')
      }

      const dataSourceMap = {
        'user-ratings': getCategoryUserRatings,
        'action-ratings-sum': getCategoryActionRatings,
        'question-objective-ratings-sum': getCategoryQuestionObjectiveRatings,
        'question-subjective-ratings-sum': getCategoryQuestionSubjectiveRatings,
      }
      return dataSourceMap[dataSource](frameworkId, compassId, categories, compassVersion)
    },
    async getActiveFrameworks ({ dispatch, state, getters }, compassId) {
      // TODO: remove all use of state.activeFrameworks and of state.allowedFrameworks
      // GET ALL FRAMEWORKS THAT WAS SELECTED ON PROJECT CREATION
      const frameworks = getters['frameworks/list']
      const compassFrameworks = getters['compasses-frameworks/list'].filter(cf => cf.compassId === compassId)
      const activeFrameworks = frameworks.filter(f => compassFrameworks.some(cf => cf.frameworkId === f.id))
      for (let i = 0; i < activeFrameworks.length; i++) {
        const datasets = await dispatch('getFrameworkDatasets', { compassId, frameworkId: activeFrameworks[i].id })
        const settings = frameworkSettings.find(s => s.frameworkId === activeFrameworks[i].id)
        activeFrameworks[i] = {
          ...activeFrameworks[i],
          ...settings,
          datasets,
        }
      }
      console.log('ACTIVE FRAMEWORKS after datasets', JSON.parse(JSON.stringify(activeFrameworks)))
      return activeFrameworks
    },
    async loadCollectionQuestions ({ dispatch }, payload) {
      const { questionCollectionId } = payload
      console.log('Loading collection questions...', { questionCollectionId })
      try {
        const collectionQuestionsResponse = await dispatch(
          'standard-questions-collections/find',
          {
            query: {
              questionCollectionId,
              $limit: 1000, // Add limit to ensure we get all questions
            },
          },
        )

        if (!collectionQuestionsResponse?.data?.length) {
          console.warn('No collection questions found')
          return []
        }

        const collectionQuestionIds = collectionQuestionsResponse.data.map(q => q.standardQuestionId)
        console.log('Collection question IDs:', collectionQuestionIds)

        const questionsResponse = await dispatch('standard-questions/find')

        console.log('Loaded questions:', questionsResponse.data)

        return questionsResponse.data || []
      } catch (error) {
        console.error('Error loading collection questions:', error)
        throw error
      }
    },
  },
  getters: {
    categoryRating: (state, getters) => (payload) => {
      const { categoryId, dataSource, frameworkId } = payload
      const categoryIndex = getters['categories/list']
        .filter(c => c.frameworkId === frameworkId)
        .findIndex(c => c.id === categoryId)

      const relevantFramework = state.activeFrameworks.find(f => f.id === frameworkId)
      if (!relevantFramework) {
        console.warn('NO RELEVANT FRAMEWORK FOUND', frameworkId)
        return 0
      }
      const relevantDataset = relevantFramework.datasets.find(d => d.dataSource === dataSource)
      if (!relevantDataset) {
        console.warn('NO RELEVANT DATASET FOUND', dataSource)
        return 0
      }
      const relevantDatasetValues = relevantDataset.values
      if (!relevantDatasetValues) {
        console.warn('NO RELEVANT DATASET VALUES FOUND', dataSource)
        return 0
      }
      return relevantDatasetValues[categoryIndex]
    },
    categoryQuestions: (state, getters) => (categoryId) => {
      const questions = getters['compass-questions/list']
      if (questions.length === 0) {
        return []
      }
      return questions.filter(q => {
        // Get the sub-category of the question
        const subCategory = getters['sub-categories/list'].find(sc => sc.id === q.subCategoryId)
        // Return items where sub-category belongs to the current category
        return subCategory && subCategory.categoryId === categoryId
      })
    },
    subCategoryQuestions: (state, getters) => (subCategoryId) => {
      const questions = getters['compass-questions/list']
      if (!questions || questions.length === 0) {
        return []
      }
      return questions.filter(q => q.subCategoryId === subCategoryId)
    },
    questionRatings: (state, getters) => (frameworkId, isObjective) => {
      isObjective = isObjective || false
      // Group questions by category and calculate averages
      const categories = getters['categories/list'].filter(cat => cat.frameworkId === frameworkId)
      const categoryRatings = categories
        .map(category => {
          return getters.categoryQuestionRating({ categoryId: category.id, isObjective })
        })
      return categoryRatings
    },
    categoryQuestionRating: (state, getters) => ({ categoryId, isObjective }) => {
      isObjective = isObjective || false
      const categoryQuestions = getters.categoryQuestions(categoryId)
      if (categoryQuestions.length === 0) {
        return 0
      }
      const answers = getters['compass-question-answers/list']
      if (answers.length === 0) {
        return 0
      }
      const relevantAnswers = answers.filter(answer => categoryQuestions.some(q => q.id === answer.compassQuestionId))
      if (relevantAnswers.length === 0) {
        return 0
      }
      return calcCategoryQuestionRating(categoryQuestions, relevantAnswers, isObjective)
    },
    subCategoryQuestionRating: (state, getters) => (subCategoryId, categoryId, isObjective) => {
      isObjective = isObjective || false
      const categoryQuestions = getters.categoryQuestions(categoryId)
      const subCategoryQuestions = getters.subCategoryQuestions(subCategoryId)
      if (subCategoryQuestions.length === 0) {
        console.warn('NO SUBCATEGORY QUESTIONS FOUND', subCategoryId)
        return 0
      }
      const answers = getters['compass-question-answers/list']
      if (answers.length === 0) {
        return 0
      }
      const relevantAnswers = answers.filter(answer => subCategoryQuestions.some(q => q.id === answer.compassQuestionId))
      if (relevantAnswers.length === 0) {
        return 0
      }
      return calcCategoryQuestionRating(categoryQuestions, relevantAnswers, isObjective)
    },
    questions: (state, getters) => (compassId) => {
      return getters['compass-questions/list'].filter(q => q.compassId === compassId)
    },
    frameworkCategories: (state, getters) => (frameworkId) => {
      // const baseUrl = process.env.VUE_APP_WEB
      const baseUrl = state.urls.appWeb
      return getters['categories/list']
        .filter(category => category.frameworkId === frameworkId)
        .sort((a, b) => a.order - b.order)
        .map(category => ({
          id: category.id,
          title: category.title,
          color: category.color,
          icon: category.icon,
          iconUrl: category.icon ? `${baseUrl}/icons/${category.icon}.svg` : '',
          description: category.description,
          frameworkId: category.frameworkId,
        }))
    },
  },
  modules: {
    actions,
    goals,
  },
  plugins: [
    ...servicePlugins,
    auth,
    // vuexLocal.plugin,
    process.env.NODE_ENV !== 'production' && !disableLogger ? createLogger() : () => {},
  ],
})

export {
  store,
}
