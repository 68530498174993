<template>
  <component :is="tagName"
    :id="'base-container-fill-height-' + uid"
    :style="`${minimum ? 'min-' : ''}height: ${height}px;`"
  >
    <slot></slot>
  </component>
</template>
<script>
import { setupScrollListener } from '@/composables/utils'

export default {
  name: 'BaseContainerFillHeight',
  props: {
    uid: {
      type: String,
      required: true,
    },
    mb: {
      type: Number,
      default: 0,
    },
    minimum: {
      type: Boolean,
      default: false,
    },
    tagName: {
      type: String,
      default: 'div',
    },
  },
  data () {
    return {
      height: 0,
    }
  },
  methods: {
    calcHeight () {
      const el = document.getElementById('base-container-fill-height-' + this.uid)
      if (!el) return 0
      const topY = el.getBoundingClientRect().top
      const windowHeight = window.innerHeight
      return windowHeight - topY - this.mb
    },
    resizeHandler () {
      this.height = this.calcHeight()
    },
  },
  mounted () {
    // Initialize the height calculation and set up the resize event listener
    this.resizeHandler()
    window.addEventListener('resize', this.resizeHandler)

    // Get the base container element by its unique ID
    const baseContainer = document.getElementById('base-container-fill-height-' + this.uid)

    // add a scroll event listener
    setupScrollListener(baseContainer, 50, 50, () => {
      this.$emit('scrollToBottom')
    })
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.resizeHandler)
  },
}
</script>
