import { store } from '@/store'

export default async (frameworkId) => {
  /**
   * IN ModalAddProject.vue
   * Get all question collections for a specific framework
   */
  try {
    store.commit('question-collections/clearAll')
    const collections = await store.dispatch('question-collections/find', { query: { frameworkId } })
    return collections.data
  } catch (error) {
    console.log('Error:', error)
  }
}
