import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Projects from '../views/Projects.vue'
import { store } from '../store/index'
import UserLogout from '../views/UserLogout.vue'

const routes = [
  {
    path: '/test',
    name: 'test',
    component: () => import(/* webpackChunkName: "test" */ '../views/Test.vue'),
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/mine-projekter',
    name: 'MyProjects',
    component: Projects,
    meta: { requiresAuth: true },
  },
  {
    path: '/projekt/:id',
    name: 'Project',
    props: true,
    component: () => import(/* webpackChunkName: "project" */ '../views/Project.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/report-designer/:compassId/:reportId?',
    name: 'ReportDesigner',
    props: (route) => {
      return { ...route.params, compassId: Number.parseInt(route.params.compassId, 10), reportId: Number.parseInt(route.params.reportId, 10) || undefined }
    },
    component: () => import(/* webpackChunkName: "report-designer" */ '../views/ReportDesigner.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/report/:reportId/:uuid',
    name: 'ReportView',
    props: true,
    component: () => import(/* webpackChunkName: "report" */ '../views/Report.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/workshop/:compassId/:step?/:subStep?/:subSubStep?',
    name: 'Workshop',
    props: true,
    component: () => import(/* webpackChunkName: "workshop" */ '../views/Workshop.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/rose/:compassId/:frameworkId',
    name: 'FullscreenCompass',
    props: (route) => {
      return { ...route.params, compassId: Number.parseInt(route.params.compassId, 10), frameworkId: Number.parseInt(route.params.frameworkId, 10) }
    },
    component: () => import(/* webpackChunkName: "project" */ '../views/FullscreenCompass.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/try',
    name: 'ExploreMobileIntro',
    component: () => import(/* webpackChunkName: "try" */ '../views/ExploreMobileIntro.vue'),
    meta: { disableSave: true },
  },
  {
    path: '/utforsk/:compassId?',
    name: 'ExploreMobile',
    props: true,
    component: () => import(/* webpackChunkName: "try" */ '../views/ExploreMobile.vue'),
    meta: { disableSave: true },
  },
  {
    path: '/forslag/:pin',
    name: 'GuestAddActions',
    props: true,
    component: () => import(/* webpackChunkName: "guest" */ '../views/GuestAddActions.vue'),
  },
  {
    path: '/kompass',
    name: 'AboutCompass',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "aboutCompass" */ '../views/AboutCompass.vue'),
  },
  {
    path: '/om',
    name: 'AboutLink',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "aboutLink" */ '../views/AboutLink.vue'),
  },
  {
    path: '/support',
    name: 'Support',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "aboutLink" */ '../views/Support.vue'),
  },
  {
    path: '/users/created',
    name: 'CreatedUser',
    component: () => import(/* webpackChunkName: "usercreate" */ '../views/CreatedUser.vue'),
  },
  {
    path: '/login',
    name: 'UserLogin',
    component: () => import(/* webpackChunkName: "login" */ '../views/UserLogin.vue'),
  },
  {
    path: '/logout',
    name: 'UserLogout',
    component: UserLogout,
  },
  {
    path: '/change-password',
    name: 'UserPassword',
    component: () => import(/* webpackChunkName: "login" */ '../views/UserPassword.vue'),
  },
  {
    path: '/users/validate/:hash/:email',
    name: 'UserValidate',
    component: () => import(/* webpackChunkName: "uservalidate" */ '../views/UserValidate.vue'),
  },
  {
    path: '/cms',
    name: 'cms',
    component: () => import(/* webpackChunkName: "cms" */ '../views/Cms.vue'),
    meta: { requiresAuth: true, requiresSuperAdmin: true },
    children: [
      {
        path: '',
        name: 'CmsMain',
        component: () => import(/* webpackChunkName: "cmsmain" */ '@/components/cms/CmsMain.vue'),
      },
      {
        path: 'category/:categoryId',
        name: 'CmsCategory',
        props: (route) => ({ categoryId: Number(route.params.categoryId) }),
        component: () => import(/* webpackChunkName: "cmscategory" */ '@/components/cms/CmsCategory.vue'),
      },
    ],
  },
  {
    path: '/ams',
    name: 'ActionManagementSystem',
    component: () => import(/* webpackChunkName: "ams" */ '../components/actionDesigner/ActionDesigner.vue'),
  },
  // Add a new route for the admin view
  {
    path: '/admin/organizations',
    name: 'OrganizationManagement',
    component: () => import(/* webpackChunkName: "OrganizationManagement" */ '../views/AdminOrgManagement.vue'),
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: '/admin/users',
    name: 'UserManagement',
    component: () => import(/* webpackChunkName: "UserManagement" */ '../views/AdminUserManagement.vue'),
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: '/framework-manager',
    name: 'FrameworkManager',
    component: () => import(/* webpackChunkName: "framework-manager" */ '../components/frameworkManager/BokvalitetDesigner.vue'),
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    component: () => import(/* webpackChunkName: "unauthorized" */ '../views/Unauthorized.vue'),
    meta: { requiresAuth: false },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      document.documentElement.scrollTop = 0
    }
  },
  routes,
})

router.beforeEach(async (to, from, next) => {
  to.meta.disableSave ? store.commit('toggleDisableSave', true) : store.commit('toggleDisableSave', false)
  const jwt = localStorage.getItem('kompass-jwt')
  let isAuthenticated = store.getters['auth/isAuthenticated']

  // Handle authentication check
  if (!isAuthenticated && jwt) {
    // JWT exists but not authenticated yet, try to authenticate
    try {
      await store.dispatch('auth/authenticate', {
        strategy: 'jwt',
        accessToken: jwt,
      })
      // Update authentication status after attempt
      isAuthenticated = store.getters['auth/isAuthenticated']
    } catch (error) {
      // Authentication failed, redirect to login
      isAuthenticated = false
    }
  }

  // Route access control
  if (to.meta.requiresAuth) {
    if (!isAuthenticated) {
      // Route requires auth but user is not authenticated
      next('/login')
      return
    }

    // User is authenticated, check for special role requirements
    if (!to.meta.requiresAdmin && !to.meta.requiresSuperAdmin) {
      // No special role requirements
      next()
      return
    }

    // Check user roles for restricted routes
    const user = store.getters['auth/user']
    const userIsAdmin = [3, 4].includes(user.role.id)
    const userIsSuperAdmin = user.role.id === 3

    if (to.meta.requiresAdmin && !userIsAdmin) {
      // Admin access required but user is not admin - redirect to unauthorized page
      // eslint-disable-next-line no-console
      console.warn(`Access denied: User tried to access admin page ${to.fullPath} without admin rights`)
      next({ name: 'Unauthorized', query: { from: to.fullPath } })
      return
    }

    if (to.meta.requiresSuperAdmin && !userIsSuperAdmin) {
      // Super admin access required but user is not super admin - redirect to unauthorized page
      // eslint-disable-next-line no-console
      console.warn(`Access denied: User tried to access super admin page ${to.fullPath} without super admin rights`)
      next({ name: 'Unauthorized', query: { from: to.fullPath } })
      return
    }

    // User has required role, proceed to route
    next()
  } else {
    // Route doesn't require authentication
    next()
  }
})

export default router
