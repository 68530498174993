<template>
  <section>
    <p class="text-lg text-gray-500 pb-6">{{ $t('Organizations.Popups.Section_question_collections_description') }}</p>
    <!-- <h2>{{ $t('Shared.collections') }}</h2> -->
    <div>
      <div v-for="collection in allowedCollections" :key="collection.value" class="flex items-center gap-2">
        <input
          type="radio"
          :id="`collection-${collection.value}`"
          :value="collection.value"
          v-model="selectedCollectionRef"
          name="question-collection"
          data-cy="collection-radio"
        >
        <label :for="`collection-${collection.value}`">{{ collection.label || 'Collection with no name' }}</label>
      </div>
    </div>
    <p class="text-sm text-red-500">{{ validationErrorMessage }}</p>
  </section>
</template>

<script>
/**
 * ProjectSectionQuestionCollections is a Vue component that renders a section for managing available question collections in a project.
 * It displays a list of collections and lets the user select one collection at a time.
 * Props:
 * - allowedCollections (Array): An array of collection objects to be displayed.
 * - selectedCollections (Array): An array containing the currently selected collection ID.
 * Emits:
 * - selectedCollectionUpdated (Array): An array containing the selected collection ID.
 */
export default {
  name: 'ProjectSectionCollections',
  props: {
    allowedCollections: {
      type: Array,
      required: true,
    },
    selectedCollection: {
      type: [Number, null],
      required: true,
    },
  },
  emits: ['selectedCollectionUpdated'],
  data () {
    return {
      selectedCollectionRef: this.selectedCollection || null,
      validationErrorMessage: null,
    }
  },
  watch: {
    allowedCollections: {
      handler (newVal) {
        console.log('allowedCollections', newVal)
        if (this.selectedCollectionRef && !newVal.find(collection => collection.value === this.selectedCollectionRef)) {
          this.selectedCollectionRef = newVal[0].value
        }
      },
      immediate: true,
    },
    selectedCollectionRef (newVal) {
      if (this.validate()) {
        this.$emit('selectedCollectionUpdated', newVal)
      }
    },
  },
  methods: {
    validate () {
      const selectedIsValid = this.validateSelectedCollection(this.selectedCollectionRef)
      return selectedIsValid
    },
    validateSelectedCollection (selectedCollection) {
      if (!selectedCollection) {
        this.validationErrorMessage = this.$t('Organizations.Popups.Validation_error_no_collections_selected')
        return false
      }
      this.validationErrorMessage = null
      return true
    },
  },
}
</script>
