<template>
  <PageLayout
    :title="$t('Projects.Project list view.Title')"
    :loading="loading"
    :error="error"
  >
    <template #main-controls>
      <BaseButton id="new-project-button" :layout="'icon-and-text'" :icon="'BasePlus'" outline @click="showAddProjectModal = true" class="btn">
        {{ $t('Projects.Project list view.Button01') }}
      </BaseButton>
    </template>
    <template #content>
      <div>
        <div class="w-full flex gap-4 mb-4">
          <BaseInput
            id="project-list-search"
            class="flex-grow"
            data-cy=""
            v-model="searchQuery"
            :label="$t('Shared.search')"
            :autoFocus="true"
            :iconName="'BaseSearch'"
          />
          <BaseSelect id="project-group-select" class="flex-grow" v-model="selectedFolder" :options="folderOptions" :label="$t('Projects.New project popup.Label04')" :placeholder="$t('Shared.choose') + ' ' + $t('Projects.New project popup.Label04')" :customArrow="true" />
        </div>
        <ProjectsList v-if="searchedCompasses.length > 0" :projects="searchedCompasses"/>
        <p v-else class="italic">{{ $t('Projects.Project list view.MessageNoProjects') }}</p>
        <!-- Only for Bokvalitetsrosen customers -->
        <div class="bokvalitet-info" v-if="isBokvalitetCustomer && this.compasses.length === 0">
          <p>Slik kommer du i gang:</p>
          <ol>
            <li><b>Utforsk verktøyet:</b> Bruk tid til å bli kjent med de ulike funksjonene og hvordan de kan brukes i prosjektet.</li>
            <li><b>Definer ambisjoner:</b> Bruk verktøyet til å avklare og dokumentere ambisjoner for boligkvalitet.</li>
            <li><b>Samarbeid:</b> Involver boligutviklere, arkitekter og kommunale planavdelinger i prosessen for å sikre en helhetlig tilnærming, og god kommunikasjon.</li>
            <li><b>Dokumentasjon:</b> Dokumenter vurderinger og beslutninger for å sikre sporbarhet og kvalitet.</li>
            <li><b>Skap boligmiks:</b> Bruk Bokvalitetsrosen til å utvikle en balansert boligmiks som møter beboernes behov og ønsker.</li>
          </ol>
          <p>Vi håper Bokvalitetsrosen vil være et nyttig verktøy arbeidet med å skape gode boliger.</p>
          <p>Er det noe du savner? Vi vil gjerne høre fra deg.</p>
          <p>Lykke til!</p>
        </div>
      </div>
    </template>
    <template #modals>
      <ModalAddProject v-model="showAddProjectModal" @create="createProject"/>
    </template>
  </PageLayout>
</template>

<script>
import ProjectsList from '@/components/projects/ProjectsList'
import BaseButton from '../components/shared/BaseButton.vue'
import ModalAddProject from '@/components/projects/ModalAddProject'
import { models, makeFindMixin } from '@feathersjs/vuex'
import BaseInput from '@/components/shared/BaseInput'
import BaseSelect from '@/components/shared/BaseSelect'
import createCompassActionsFromCollection from '@/composables/actions/createCompassActionsFromCollection'
import createCompassQuestionsFromCollection from '@/composables/questionCollections/createCompassQuestionsFromCollection'
import PageLayout from '@/components/layouts/PageLayout.vue'
export default {
  components: {
    ProjectsList,
    BaseButton,
    ModalAddProject,
    BaseInput,
    BaseSelect,
    PageLayout,
  },
  data () {
    return {
      error: null,
      loading: true,
      showAddProjectModal: false,
      searchQuery: '',
      selectedFolder: 'all',
      folderOptions: [{ value: 'all', label: this.$t('Shared.all') }],
    }
  },
  computed: {
    isBokvalitetCustomer () {
      if (this.$store.getters['frameworks/list'].some(framework => framework.id === 4) && this.$store.getters['frameworks/list'].length === 1) {
        return true
      }
      return false
    },
    compassesParams () {
      return {}
    },
    compassesFetchParams () {
      return {}
    },
    filteredCompasses () {
      if (this.selectedFolder === 'all' || this.selectedFolder === null) {
        return this.compasses
      }
      if (this.selectedFolder === 'outside') {
        return this.compasses.filter(compass => !compass.compassFolderId)
      }
      return this.compasses.filter(compass => compass.compassFolderId === this.selectedFolder)
    },
    searchedCompasses () {
      if (this.searchQuery === '') {
        return this.filteredCompasses
      }
      const foldersWithQueryInTitle = this.$store.getters['compass-folder/list'].filter(folder => folder.title.toLowerCase().includes(this.searchQuery.toLowerCase()))
      return this.filteredCompasses.filter(compass => (
        compass.title.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
        compass.description.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
        foldersWithQueryInTitle.some(folder => folder.id === compass.compassFolderId)
      ))
    },
  },
  mixins: [makeFindMixin({ service: 'compasses' })],
  methods: {
    async createProject (project) {
      try {
        this.loading = true
        console.log('create project', project)
        if (project.folderId === 'new') {
          const newProjectFolder = await new models.api.CompassFolder()
          newProjectFolder.title = project.folderTitle
          const _createdFolder = await newProjectFolder.create()
          project.folderId = _createdFolder.id
        }
        const newCompass = await new models.api.Compass()
        newCompass.title = project.title
        newCompass.description = project.description
        newCompass.projectNumber = project.projectNumber
        newCompass.compassFolderId = project.folderId
        newCompass.countryId = project.countryId
        newCompass.frameworkIds = project.frameworkIds
        const result = await this.$store.dispatch(
          'compasses/create',
          [newCompass, { query: { collectionId: project.collectionId, questionCollectionId: project.questionCollectionId } }],
        )
        console.log('new project was created', result)
        const compassId = result.id
        if (project.collectionId) {
          console.log('adding collection')
          await createCompassActionsFromCollection(project.collectionId, compassId)
        }
        if (project.questionCollectionId) {
          console.log('adding question collection')
          await createCompassQuestionsFromCollection(project.questionCollectionId, compassId)
        }
        // Add compass-workshop-steps
        /*
        await this.$store.dispatch('compass-workshop-steps/create', [{}, {
          query: {
            compassId: compassId,
          },
        }])
        */
        console.log('redirecting to new project')
        this.$router.push(`/projekt/${compassId}`)
      } catch (e) {
        console.log(e)
        this.loading = false
      }
      // this.$store.state
    },
  },
  async mounted () {
    try {
      await this.$store.dispatch('refreshStandardActions', { isEnabled: true })
      await this.$store.dispatch('compass-folder/find')
      const folders = this.$store.getters['compass-folder/list'].map(folder => ({ value: folder.id, label: folder.title }))
      const allFoldersOption = { value: 'all', label: this.$t('Shared.all') + ' ' + this.$t('Shared.folders') }
      const outsideFolderOption = { value: 'outside', label: this.$t('Shared.outside') + ' ' + this.$t('Shared.folder') }
      this.folderOptions = [allFoldersOption, ...folders, outsideFolderOption]
      this.selectedFolder = null
      await this.$store.dispatch('compasses/find')
      // await this.$store.dispatch('loadCompassData', this.$store.state.compasses.ids)
      this.loading = false
    } catch (error) {
      console.log(error)
      this.loading = false
      this.error = 'Der opstod en fejl'
      /*
      if (error.code === 401) {
        await this.$store.dispatch('auth/logout')
        this.$router.push('/login')
      } else {
        this.error = 'Der opstod en fejl'
      }
      */
    }
  },
}
</script>

<style scoped>
.bokvalitet-info {
  margin-top: 2rem;
  padding: 1rem;
  background-color: #f5f5f5;
  border-radius: 0.5rem;
}
.bokvalitet-info p {
  margin-bottom: 0.5rem;
}
.bokvalitet-info ol {
  margin-left: 1rem;
  margin-bottom: 1rem;
}
.bokvalitet-info ol li {
  list-style: decimal;
  margin-left: 1rem;
}
</style>
