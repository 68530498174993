import { store } from '@/store'

export default async (questionCollectionId, compassId) => {
  try {
    const response = await store.dispatch('compass-questions/create', [{}, {
      query: {
        questionCollectionId,
        compassId,
        localeId: 1,
      },
    }])
    console.log('Response:', response)
    await store.dispatch('compass-questions/find', compassId)
    console.log(JSON.parse(JSON.stringify(store.getters.questions(compassId))))
  } catch (error) {
    console.error('Error occurred while creating compass questions:', error)
    console.error('Error stack:', error.stack)
  }
}
