export function createChartConfig (categories, datasets, options) {
  /**
   * chart config object
   * @param {Array} categories - array of category objects with label, iconUrl, and color properties
   * @param {Array} datasets - array of dataset objects with values property
   * @param {Object} options - chart configuration options
   */
  const chart = {
    // DATA
    labels: categories.map(category => category.title),
    iconUrls: categories.map(category => category.iconUrl),
    colors: categories.map(category => category.color),
    datasets: JSON.parse(JSON.stringify(datasets)),
    // VALUE SETTINGS
    maxValue: options.maxValue || 100,
    steps: options.steps || 10,
    // STYLE SETTINGS
    gap: options.gap || 1,
    innerRadius: options.innerRadius || 20, // radius of the donut hole
    iconSize: options.iconSize || 20, // height of the icon in pixels
    iconPadding: options.iconPadding || 8, // inner and outer padding of icon
    contextBackground: options.contextBackground || 'rgb(238, 238, 238)',
    arcBackgroundColor: options.arcBackgroundColor || 'rgba(243, 244, 246, 0)',
    arcFillColor: options.arcFillColor || 'blue',
    arcHoverColor: options.arcHoverColor || 'rgba(253, 226, 116, 0.65)',
    categoryBackgroundColor: options.categoryBackgroundColor || 'rgba(0, 0, 0, 0)',
    categoryHoverColor: options.categoryHoverColor || 'lightgray',
    markerLineColor: options.markerLineColor || 'rgba(253, 226, 116, 1)', // color: red
    // BEHAVIOR SETTINGS
    canRate: options.canRate !== undefined ? options.canRate : true,
    allowCategoryClick: options.allowCategoryClick !== undefined ? options.allowCategoryClick : true,
    onRate: options.onRate || (() => {}),
    canHover: options.canHover !== undefined ? options.canHover : true,
    viewMode: options.viewMode || 'stacked',
    // SIZE SETTINGS
    width: options.width || 100,
    height: options.height || 200,
    // USED IN DRAWING
    container: null,
    backgroundLayer: null,
    dataLayers: [],
    interactionLayer: null,
    infoLayer: null,
    roseCenter: null, // is set on init
    outerRadius: null, // radius of the outer circle (is set on init)
  }
  if (chart.viewMode === 'stacked') {
    chart.maxValue = chart.maxValue * chart.datasets.length
  }
  return chart
}
