import feathersClient from '@/feathers-client'

/**
 * Normalizes Feathers response to always be an array
 * @param {Array|Object} response - API response from Feathers
 * @returns {Array} Normalized array of items
 */
const normalizeResponse = (response) => {
  if (Array.isArray(response)) {
    return response
  }
  return response?.data || []
}

/**
 * Gets action ratings for multiple categories in a compass
 * @param {number} frameworkId - The ID of the framework
 * @param {string|number} compassId - The ID of the compass
 * @param {Array} categories - Array of category objects
 * @param {number} compassVersion - The version of the compass
 * @returns {Promise<Array<number>>} Array of category action ratings
 */
export const getCategoryActionRatings = async (frameworkId, compassId, categories, compassVersion = 2) => {
  // Validate required parameters
  if (!compassId || !frameworkId || !categories || categories.length === 0) {
    return categories.map(() => 0)
  }

  // Handle version 1 compass - need to get old-style ratings
  if (compassVersion === 1) {
    try {
      const oldCompassCatsResponse = await feathersClient.service('rating-data').find({
        query: {
          frameworkId,
          compassId,
        },
      })

      const oldCompassCats = normalizeResponse(oldCompassCatsResponse)

      if (oldCompassCats.length === 0) {
        return categories.map(() => 0)
      }

      return categories.map(category => {
        const match = oldCompassCats.find(cat => cat.id === category.id)
        return match ? match.value : 0
      })
    } catch (error) {
      console.error('Error getting old compass ratings:', error)
      return categories.map(() => 0)
    }
  }

  // For version 2+ compasses
  try {
    if (frameworkId === 1) {
      // For framework 1, get all action ratings in one request
      const allActionRatingsResponse = await feathersClient.service('action-ratings').find({
        query: {
          compassId,
          categoryId: {
            $in: categories.map(category => category.id),
          },
        },
      })

      const allActionRatings = normalizeResponse(allActionRatingsResponse)

      if (allActionRatings.length === 0) {
        return categories.map(() => 0)
      }

      // Group the ratings by category and sum them
      return categories.map(category => {
        const categoryRatings = allActionRatings.filter(
          rating => rating.categoryId === category.id,
        )
        return categoryRatings.reduce((sum, rating) => sum + rating.rating, 0)
      })
    } else if (frameworkId === 3) {
      // For framework 3:
      // 1. Get all subcategory mappings in one request
      const allMappingsResponse = await feathersClient.service('sub-categories-defaults').find({
        query: {
          frameworksCategoryId: {
            $in: categories.map(category => category.id),
          },
        },
      })

      const allMappings = normalizeResponse(allMappingsResponse)

      if (allMappings.length === 0) {
        return categories.map(() => 0)
      }

      // Create a map of category IDs to their mapped subcategory IDs
      const categoryToSubCategoriesMap = {}
      allMappings.forEach(mapping => {
        if (!categoryToSubCategoriesMap[mapping.frameworksCategoryId]) {
          categoryToSubCategoriesMap[mapping.frameworksCategoryId] = []
        }
        categoryToSubCategoriesMap[mapping.frameworksCategoryId].push(mapping.subCategoryId)
      })

      // Get all relevant subcategory IDs
      const allMappedSubCatIds = allMappings.map(mapping => mapping.subCategoryId)

      // 2. Get all compass actions for these subcategories in one request
      const allCompassActionsResponse = await feathersClient.service('compass-actions').find({
        query: {
          compassId,
          subCategoryId: {
            $in: allMappedSubCatIds,
          },
        },
      })

      const allCompassActions = normalizeResponse(allCompassActionsResponse)

      if (allCompassActions.length === 0) {
        return categories.map(() => 0)
      }

      // Create a map of subcategory IDs to the compass action IDs they contain
      const subCategoryToActionIdsMap = {}
      allCompassActions.forEach(action => {
        if (!subCategoryToActionIdsMap[action.subCategoryId]) {
          subCategoryToActionIdsMap[action.subCategoryId] = []
        }
        subCategoryToActionIdsMap[action.subCategoryId].push(action.id)
      })

      // Get all compass action IDs
      const allCompassActionIds = allCompassActions.map(action => action.id)

      // 3. Get all ratings for these actions in one request
      const allRatingsResponse = await feathersClient.service('action-ratings').find({
        query: {
          compassActionId: {
            $in: allCompassActionIds,
          },
        },
      })

      const allRatings = normalizeResponse(allRatingsResponse)

      if (allRatings.length === 0) {
        return categories.map(() => 0)
      }

      // Calculate ratings for each category
      return categories.map(category => {
        const categorySubCatIds = categoryToSubCategoriesMap[category.id] || []

        // Get all action IDs associated with this category's subcategories
        const categoryActionIds = categorySubCatIds.flatMap(
          subCatId => subCategoryToActionIdsMap[subCatId] || [],
        )

        // Sum up ratings for all these actions
        const categoryRatings = allRatings.filter(
          rating => categoryActionIds.includes(rating.compassActionId),
        )

        return categoryRatings.reduce((sum, rating) => sum + rating.rating, 0)
      })
    } else {
      // For other frameworks, return zeros
      return categories.map(() => 0)
    }
  } catch (error) {
    console.error('Error getting category action ratings:', error)
    return categories.map(() => 0)
  }
}
