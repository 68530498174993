export const calcCategoryQuestionRating = (questions, answers, isObjective) => {
  if (questions.length === 0) {
    return 0
  }
  if (answers.length === 0) {
    return 0
  }
  console.log('ANSWERS', answers)
  const mappedQuestions = questions
    .filter(question => [1, 2].includes(question.voteTypeId))
    .filter(question => question.isObjective === isObjective)
    .map(question => {
      const answer = answers.find(answer => answer.compassQuestionId === question.id)
      let answerValue = 0
      console.log('answer', answer)
      if (!answer) {
        answerValue = 0
      } else if (answer.value === 'N/A' || answer.value === null) {
        answerValue = null
      } else {
        answerValue = answer.value
      }
      return {
        ...question,
        answer: answerValue,
      }
    })

  if (mappedQuestions.length === 0) {
    return 0
  }
  console.log('CALCULATING CATEGORY POINTS' + (isObjective ? ' (OBJECTIVE)' : ' (SUBJECTIVE)'))
  console.log('mappedQuestions', mappedQuestions.map(a => a.answer))
  // TODO: get max rating from question.maxRating
  const relevantQuestions = mappedQuestions.filter(question => question.answer !== null)
  const maxSum = relevantQuestions.length * 4
  console.log('maxSum', maxSum)
  const currentSum = relevantQuestions
    .reduce((sum, question) => {
      console.log('question', question.answer)
      return sum + Number(question.answer)
    }, 0)
  console.log('currentSum', currentSum)
  return (currentSum / maxSum * 100).toFixed(0)
}
