import feathersClient from '@/feathers-client'
import { calcCategoryQuestionRating } from './calcCategoryQuestionRating'

/**
 * Normalizes Feathers response to always be an array
 * @param {Array|Object} response - API response from Feathers
 * @returns {Array} Normalized array of items
 */
const normalizeResponse = (response) => {
  if (Array.isArray(response)) {
    return response
  }
  return response?.data || []
}

/**
 * Gets questions for a specific category using its subcategories
 * @param {string|number} categoryId - The ID of the category
 * @param {Array} questions - Array of question objects
 * @param {Array} subCategories - Array of subcategory objects
 * @returns {Array} Questions belonging to the category
 */
const getCategoryQuestions = (categoryId, questions, subCategories) => {
  return questions.filter(q => {
    // Get the sub-category of the question
    const subCategory = subCategories.find(sc => sc.id === q.subCategoryId)
    // Return items where sub-category belongs to the current category
    return subCategory && subCategory.categoryId === categoryId
  })
}

/**
 * Gets subjective question ratings for multiple categories in a compass
 * @param {number} frameworkId - The ID of the framework
 * @param {string|number} compassId - The ID of the compass
 * @param {Array} categories - Array of category objects
 * @returns {Promise<Array<number>>} Array of category subjective question ratings
 */
export const getCategoryQuestionSubjectiveRatings = async (frameworkId, compassId, categories) => {
  // Validate required parameters
  if (!compassId || !frameworkId || !categories || categories.length === 0) {
    return categories.map(() => 0)
  }

  try {
    // Fetch all subcategories for these categories in one request
    const subCategoriesResponse = await feathersClient.service('sub-categories').find({
      query: {
        categoryId: {
          $in: categories.map(c => c.id),
        },
      },
    })

    // Normalize the response
    const subCategoriesData = normalizeResponse(subCategoriesResponse)

    if (subCategoriesData.length === 0) {
      console.error('Failed to retrieve subcategories data or no subcategories found')
      return categories.map(() => 0)
    }

    // Get all subjective questions for this compass in one request
    const questionsResponse = await feathersClient.service('compass-questions').find({
      query: { compassId, isObjective: false },
    })

    // Normalize the response
    const questions = normalizeResponse(questionsResponse)

    if (questions.length === 0) {
      return categories.map(() => 0)
    }

    // Get all answers for these questions in one request
    const answersResponse = await feathersClient.service('compass-question-answers').find({
      query: {
        compassQuestionId: {
          $in: questions.map(q => q.id),
        },
      },
    })

    // Normalize the response
    const answers = normalizeResponse(answersResponse)

    // Calculate ratings for each category
    return categories.map(category => {
      // Get questions that belong to this category
      const categoryQuestions = getCategoryQuestions(category.id, questions, subCategoriesData)

      if (!categoryQuestions || categoryQuestions.length === 0) {
        return 0
      }

      // Filter answers for this category's questions
      const categoryAnswers = answers.filter(answer =>
        categoryQuestions.some(q => q.id === answer.compassQuestionId),
      )

      // Calculate rating using the existing function
      return calcCategoryQuestionRating(categoryQuestions, categoryAnswers, false)
    })
  } catch (error) {
    console.error('Error getting category subjective question ratings:', error)
    return categories.map(() => 0)
  }
}
