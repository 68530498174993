import feathersClient, { makeServicePlugin, BaseModel } from '../../feathers-client'

// Define the Organization model
class Organization extends BaseModel {
  static modelName = 'Organization'
  static instanceDefaults () {
    return {
      name: '',
      address: null,
      zip: null,
      city: null,
      phone: null,
      contactPerson: null,
      contactEmail: null,
      contactPhone: null,
      parentOrganizationId: null,
    }
  }
}
// Create the service plugin
const servicePath = 'organizations'
const servicePlugin = makeServicePlugin({
  Model: Organization,
  service: feathersClient.service(servicePath),
  servicePath,
})
export default servicePlugin
