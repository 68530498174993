<template>
  <div
    class="z-10 absolute bottom-0 right-0 left-0 py-3 px-6 flex gap-2 justify-end bg-gray-700"
    data-cy="form-action-container"
  >
    <BaseButton
      v-if="canCancel && labels.cancel"
      type="button"
      id="cancel-section-button"
      primary
      data-cy="cancel-button"
      @click="$emit('cancel')"
    >
      {{ labels.cancel }}
    </BaseButton>
    <BaseButton
      v-if="currentSectionIndex > 0"
      type="button"
      id="previous-section-button"
      primary
      data-cy="previous-button"
      @click="$emit('previous')"
    >
      {{ labels.previous }}
    </BaseButton>
    <BaseButton
      v-if="currentSectionIndex < maxSectionIndex"
      type="button"
      id="next-section-button"
      primary
      data-cy="next-button"
      @click="$emit('next')"
    >
      {{ labels.next }}
    </BaseButton>
    <BaseButton
      v-if="currentSectionIndex === maxSectionIndex"
      type="button"
      id="createOrganization"
      primary
      data-cy="create-button"
      @click="$emit('create')"
    >
      {{ labels.create }}
    </BaseButton>
    <BaseButton
      v-if="canSkip && labels.skip"
      type="button"
      id="skip-section-button"
      primary
      data-cy="skip-button"
      @click="$emit('skip')"
    >
      {{ labels.skip }}
    </BaseButton>
  </div>
</template>

<script>
import BaseButton from './BaseButton.vue'

export default {
  components: {
    BaseButton,
  },
  props: {
    currentSectionIndex: {
      type: Number,
      required: true,
    },
    maxSectionIndex: {
      type: Number,
      required: true,
    },
    canSkip: {
      type: Boolean,
      default: false,
    },
    canCancel: {
      type: Boolean,
      default: false,
    },
    labels: {
      type: Object,
      default: () => ({
        previous: 'Previous',
        next: 'Next',
        create: 'Create',
        skip: 'Skip',
        cancel: 'Cancel',
      }),
    },
  },
}
</script>

<style scoped>
</style>
