<template>
  <section>
    <p class="text-lg text-gray-500 pb-6">{{ $t('Projects.New project popup.Section_frameworks_description') }}</p>
    <h2>{{ $t('Projects.New project popup.Section_frameworks_title') }}</h2>
    <div>
      <div v-for="framework in allowedFrameworks" :key="framework.id" class="flex items-center gap-2">
        <input type="checkbox" :id="`framework-${framework.id}`" :value="framework.id" v-model="selectedFrameworksRef" data-cy="framework-checkbox">
        <label :for="`framework-${framework.id}`">{{ framework.title }}</label>
      </div>
    </div>
    <p class="text-sm text-red-500">{{ validationErrorMessage }}</p>
  </section>
</template>

<script>
/**
 * ProjectSectionFrameworks is a Vue component that renders a section for managing available frameworks in a project.
 * It displays a list of frameworks and lets the user enable or disable them.
 * Props:
 * - frameworks (Array): An array of framework objects to be displayed.
 * Emits:
 * - selectedFrameworksUpdated (Array): An array of framework objects to be displayed.
 */
export default {
  name: 'ProjectSectionFrameworks',
  props: {
    allowedFrameworks: {
      type: Array,
      required: true,
    },
    selectedFrameworks: {
      type: Array,
      required: true,
    },
  },
  emits: ['selectedFrameworksUpdated'],
  data () {
    return {
      selectedFrameworksRef: [],
      validationErrorMessage: null,
    }
  },
  watch: {
    selectedFrameworks: {
      handler (newVal) {
        console.log('allowedFrameworks', newVal)
        this.selectedFrameworksRef = newVal
      },
      immediate: true,
    },
    selectedFrameworksRef (newVal) {
      if (this.validate()) {
        this.$emit('selectedFrameworksUpdated', newVal)
      }
    },
  },
  methods: {
    validate () {
      // using consts to make sure both validations are run
      const selectedIsValid = this.validateSelectedFrameworks(this.selectedFrameworksRef)
      return selectedIsValid
    },
    validateSelectedFrameworks (allowedFrameworks) {
      if (allowedFrameworks.length === 0) {
        this.validationErrorMessage = this.$t('Organizations.Popups.Validation_error_no_frameworks_selected')
        return false
      }
      this.validationErrorMessage = null
      return true
    },
  },
}
</script>
