<template>
  <li class="cursor-pointer hover:bg-gray-100 rounded p-2" @click="$router.push(`/projekt/${projectId}`)">
    <RoseChartD3
      v-if="showChart"
      :chartId="`panelchart-${projectId}`"
      :compassId="projectId"
      :frameworkId="currentFramework.frameworkId"
      :categories="categories"
      :datasets="this.datasets"
      :options="{
        canSelectCategory: false,
        canSaveRatings: false,
        clickable: false,
        highlightSelectedCategory: false,
        canHover: false,
        allowCategoryClick: false,
        maxValue: currentFramework.maxValue,
        steps: currentFramework.steps,
        viewMode: currentFramework.viewMode,
        markerLineColor: 'rgba(253, 0, 116, 0)',
      }"
    />
    <div class="relative">
      <h2 class="text-2xl mt-2 text-center">
        {{ projectTitle !== '' ? projectTitle : 'NO NAME' }}
      </h2>
      <div v-if="user && project.userId !== user.id" class="bg-white text-sm text-gray-500 text-center p-1 border-2 text-uppercase w-fit absolute right-0 -top-10">
        {{ $t('Projects.Project list view.SharedWithMe') }}
      </div>
    </div>
  </li>
</template>

<script>
import RoseChartD3 from '@/components/shared/RoseChartD3'
import feathersClient from '@/feathers-client'
import { frameworkLayerSources, frameworkSettings } from '@/data'
import { getCategoryUserRatings } from '@/composables/ratings/getCategoryUserRating'
import { getCategoryActionRatings } from '@/composables/ratings/getCategoryActionRating'
import { getCategoryQuestionObjectiveRatings } from '@/composables/ratings/getCategoryQuestionObjectiveRating'
import { getCategoryQuestionSubjectiveRatings } from '@/composables/ratings/getCategoryQuestionSubjectiveRating'

export default {
  components: {
    RoseChartD3,
  },
  props: {
    projectId: {
      type: Number,
      required: true,
    },
    projectTitle: {
      type: String,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      showChart: false,
      currentFramework: null,
      categories: [],
      datasets: [],
    }
  },
  computed: {
    user () {
      return this.$store.getters['auth/user']
    },
  },
  async mounted () {
    // Use feathersClient directly to avoid populating the Vuex store
    const result = await feathersClient.service('compasses-frameworks').find({
      query: { compassId: this.projectId },
    })
    let defaultFramework = result.data.find(framework => framework.isDefault)
    const settings = frameworkSettings.find(s => s.frameworkId === defaultFramework.frameworkId)
    if (settings) {
      defaultFramework = { ...defaultFramework, ...settings }
    }
    const frameworkId = defaultFramework.frameworkId
    const currentFrameworkLayerSources = frameworkLayerSources.filter(layer => layer.frameworkId === frameworkId)
    const categories = await feathersClient.service('categories').find({ query: { frameworkId: frameworkId } })
    const compasses = await feathersClient.service('compasses').find({ query: { id: this.projectId } })
    console.log('compasses', compasses)
    let compassVersion = 2
    if (Array.isArray(compasses)) {
      compassVersion = compasses[0].version
    } else if (compasses.data && Array.isArray(compasses.data)) {
      compassVersion = compasses.data[0].version
    } else if (compasses.data) {
      compassVersion = compasses.data.version
    }
    // Forkert herunder? TODO
    this.categories = categories.data
      .map(category => ({
        ...category,
        // iconUrl: category.icon ? `${process.env.VUE_APP_WEB}/icons/${category.icon}.svg` : '',
        iconUrl: category.icon ? `${this.$store.state.urls.appWeb}/icons/${category.icon}.svg` : '',
      }))
    this.currentFramework = defaultFramework
    const sourceMap = {
      'user-ratings': getCategoryUserRatings,
      'action-ratings-sum': getCategoryActionRatings,
      'question-objective-ratings-sum': getCategoryQuestionObjectiveRatings,
      'question-subjective-ratings-sum': getCategoryQuestionSubjectiveRatings,
    }
    this.datasets = []
    for (const layer of currentFrameworkLayerSources) {
      const source = sourceMap[layer.dataSource]
      if (source) {
        const values = await source(frameworkId, this.projectId, this.categories, compassVersion)
        this.datasets.push({
          label: layer.label,
          opacity: layer.opacity,
          roundToStep: layer.roundToStep,
          frameworkId: layer.frameworkId,
          values,
        })
      }
    }
    this.showChart = true
  },
}
</script>

<style scoped>
  .w-fit {
    width: fit-content;
  }
</style>
