import feathersClient from '@/feathers-client'

/**
 * Gets user ratings for multiple categories in a compass
 * @param {number} frameworkId - The ID of the framework
 * @param {string|number} compassId - The ID of the compass
 * @param {Array} categories - Array of category objects
 * @returns {Promise<Array<number>>} Array of category ratings
 */
export const getCategoryUserRatings = async (frameworkId, compassId, categories) => {
  console.log('getCategoryUserRatings called with:', { frameworkId, compassId, categoriesCount: categories?.length })

  // Validate required parameters
  if (!compassId || !frameworkId || !categories || categories.length === 0) {
    console.log('Missing required parameters for getCategoryUserRatings')
    return categories.map(() => 0)
  }

  try {
    // First, find the highlighted user rating for this compass and framework
    console.log('Fetching user ratings for compass:', compassId, 'framework:', frameworkId)
    const compassUserRatingsResponse = await feathersClient.service('compass-user-ratings').find({
      query: {
        compassId,
        frameworkId,
        isHighlighted: true,
      },
    })

    // Handle both response formats (array or object with data property)
    const compassUserRatings = Array.isArray(compassUserRatingsResponse)
      ? compassUserRatingsResponse
      : compassUserRatingsResponse?.data || []

    if (compassUserRatings.length === 0) {
      console.log('No user ratings found for this compass and framework', { compassId, frameworkId })
      return categories.map(() => 0)
    }

    const compassUserRatingId = compassUserRatings[0]?.id
    console.log('Found compassUserRatingId:', compassUserRatingId)
    console.log('Categories:', categories.map(category => ({ id: category.id, name: category.name })))

    // Then get all category ratings for this user rating in a single request
    console.log('Fetching category ratings with params:', {
      compassId,
      categoryIds: categories.map(category => category.id),
      compassUserRatingId,
    })

    const categoryRatingsResponse = await feathersClient.service('compass-categories-ratings').find({
      query: {
        compassId,
        categoryId: {
          $in: categories.map(category => category.id),
        },
        compassUserRatingId,
      },
    })

    // Handle both response formats (array or object with data property)
    const categoryRatings = Array.isArray(categoryRatingsResponse)
      ? categoryRatingsResponse
      : categoryRatingsResponse?.data || []

    console.log('Category ratings response type:', typeof categoryRatingsResponse)
    console.log('Is array?', Array.isArray(categoryRatingsResponse))
    console.log('Has data property?', !!categoryRatingsResponse?.data)

    if (categoryRatings.length === 0) {
      console.log('No category ratings found for this user rating. This could be normal for new compasses.', {
        compassId,
        compassUserRatingId,
        categoryIds: categories.map(c => c.id),
      })
      return categories.map(() => 0)
    }

    console.log('Found category ratings:', categoryRatings.length)

    // Map the results back to the categories array
    return categories.map(category => {
      const rating = categoryRatings.find(r => r.categoryId === category.id)
      if (!rating) {
        console.log(`No rating found for category ${category.id}`)
      }
      return rating?.rating || 0
    })
  } catch (error) {
    console.error('Error getting category user ratings:', error)
    return categories.map(() => 0)
  }
}
