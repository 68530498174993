<template>
  <div
    ref="coverImage"
    class="w-full h-192 bg-gray-200 bg-cover"
    :style="'background-image: url(' + coverUrl + ');'"
  >
    <div v-if="!isLink" class="absolute bottom-10 left-10 text-black text-2xl bg-white/80 px-4 py-3 max-w-3xl">
      <h1 class="inline-block">Et vurderingssystem for bokvalitet</h1>
      <h2>Bokvalitetsrosen er et innovativt arbeidsverktøy som vurderer, definerer og dokumenterer bokvaliteter i boligprosjektet. Det fungerer som et dialogverktøy for boligutviklere, arkitekter og kommunale planavdelinger, og kan også brukes internt hos boligutviklere for å avklare ambisjoner. Bokvalitetsrosen bidrar til å skape en balansert boligmiks og utvikle boliger som beboerne virkelig ønsker seg.</h2>
    </div>
  </div>
</template>

<script>
export default {
  mounted () {
    this.$refs.coverImage.style.height = '100vh'
  },
  computed: {
    coverUrl () {
      if (this.isLink) {
        return '/images/page_about_cover.jpg'
      }
      return '/images/page_about_cover_bokvalitet.jpg'
    },
    isLink () {
      return ['https://app.linkkompass.ai', 'https://kompass.linkarkitektur.com'].includes(window.location.origin)
    },
  },
}
</script>

<style scoped>
  h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  h2 {
    @apply font-normal;
    @apply text-base;
  }
</style>
