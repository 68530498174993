<template>
  <div class="grid grid-cols-12 mb-5 gap-4">
    <BaseInput
      id="new-project-title"
      data-cy="project-title-input"
      :modelValue="title"
      @update:modelValue="updateTitle"
      :label="$t('Projects.New project popup.Label01')"
      :autoFocus="true"
      class="col-span-8"
    />
    <BaseInput
      id="new-project-number"
      data-cy="project-number-input"
      :modelValue="projectNumber"
      @update:modelValue="updateProjectNumber"
      :label="$t('Projects.New project popup.Label03')"
      ref="projectNumber"
      class="col-span-4"
    />
  </div>
  <BaseSelect
    id="project-group-select"
    data-cy="project-folder-select"
    :modelValue="folderId"
    @update:modelValue="updateFolderId"
    :options="folderOptions"
    :label="$t('Projects.New project popup.Label04')"
    :placeholder="$t('Projects.New project popup.Label04')"
    class="mb-5"
    :customArrow="true"
  />
  <BaseInput
    v-if="folderId === 'new'"
    id="new-project-group"
    data-cy="project-folder-title-input"
    :modelValue="folderTitle"
    @update:modelValue="updateFolderTitle"
    :label="$t('Projects.New project popup.Placeholder01')"
    class="mb-5"
  />
  <BaseSelect
    id="project-country-select"
    data-cy="project-country-select"
    :modelValue="countryId"
    @update:modelValue="updateCountryId"
    :options="countryOptions"
    :label="$t('Projects.New project popup.Select_country_label')"
    :placeholder="$t('Projects.New project popup.Select_country_placeholder')"
    class="mb-5"
    :customArrow="true"
  />
  <BaseTextarea
    id="new-project-description"
    data-cy="project-description-input"
    :modelValue="description"
    @update:modelValue="updateDescription"
    :label="$t('Projects.New project popup.Label02')"
    class="mb-5"
  />
</template>

<script>
import BaseTextarea from '@/components/shared/BaseTextarea'
import BaseInput from '@/components/shared/BaseInput'
import BaseSelect from '@/components/shared/BaseSelect'

export default {
  components: {
    BaseInput,
    BaseTextarea,
    BaseSelect,
  },
  props: {
    title: {
      type: [String, null],
      required: true,
    },
    description: {
      type: [String, null],
      required: true,
    },
    projectNumber: {
      type: [String, null],
      required: true,
    },
    folderId: {
      type: [Number, null],
      required: true,
    },
    folderTitle: {
      type: [String, null],
      required: true,
    },
    countryId: {
      type: [Number, null],
      required: true,
    },
    folderOptions: {
      type: Array,
      required: true,
    },
    countryOptions: {
      type: Array,
      required: true,
    },
  },
  emits: ['update:title', 'update:description', 'update:projectNumber', 'update:folderId', 'update:folderTitle', 'update:countryId'],
  methods: {
    updateTitle (val) {
      console.log('updateTitle', val)
      this.$emit('update:title', val)
    },
    updateDescription (val) {
      this.$emit('update:description', val)
    },
    updateProjectNumber (val) {
      this.$emit('update:projectNumber', val)
    },
    updateFolderId (val) {
      this.$emit('update:folderId', val)
    },
    updateFolderTitle (val) {
      this.$emit('update:folderTitle', val)
    },
    updateCountryId (val) {
      console.log('updateCountryId', val)
      this.$emit('update:countryId', val)
    },
  },
}
</script>
