import feathersClient, { makeServicePlugin, BaseModel } from '../../feathers-client'

class Compass extends BaseModel {
  // Required for $FeathersVuex plugin to work after production transpile.

  static modelName = 'Compass'
  // Define default properties here
  static instanceDefaults () {
    return {
      title: '',
      description: '',
      activeSubCategories: [],
      projectNumber: '',
      vizFrameworkId: 1,
      visibility: 'private',
      userId: null,
      compassStateId: null,
      version: 2,
      workshopSettings: {},
    }
  }

  static setupInstance (data, { models }) {
    if (data.compassCategoriesRatings) {
      data.compassCategoriesRatings = data.compassCategoriesRatings.map(rating => new models.api.CompassCategoryRating(rating))
    }
    return data
  }
}
const servicePath = 'compasses'
const servicePlugin = makeServicePlugin({
  Model: Compass,
  service: feathersClient.service(servicePath),
  servicePath,
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
})

export default servicePlugin
