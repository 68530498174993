// THIS FILE CONTAINS HARDCODED DATA USED IN VARIOUS PLACES
// TODO: MOVE TO DB

export const defaultWorkshopSteps = (t) => {
  return [
    {
      name: 'vision',
      label: t('Workshop.Steps.Step1Label'),
      description: t('Workshop.Steps.Step1Description'),
      subSteps: [],
      selectedSubSteps: [],
    },
    /* {
      name: 'visionrose',
      label: t('Workshop.Steps.Step2Label'),
      description: t('Workshop.Steps.Step2Description'),
      subSteps: [],
      selectedSubSteps: [],
    }, */
    /* {
      name: 'guide',
      label: t('Workshop.Steps.Step3Label'),
      description: t('Workshop.Steps.Step3Description'),
      subSteps: [
        { name: 'intro', label: t('Workshop.Steps.StepGuideSubStep1Label') },
        { name: 'omraader', label: t('Workshop.Steps.StepGuideSubStep2Label') },
        { name: 'ambition', label: t('Workshop.Steps.StepGuideSubStep4Label') },
        { name: 'tiltag', label: t('Workshop.Steps.StepGuideSubStep3Label') },
      ],
      selectedSubSteps: ['intro', 'omraader', 'ambition', 'tiltag'],
      frameworkId: 1,
    }, */
    {
      name: 'survey',
      label: t('Workshop.Steps.Step3bLabel'),
      description: t('Workshop.Steps.Step3bDescription'),
      subSteps: [],
      selectedSubSteps: [],
      frameworkId: 4,
    },
    /* {
      name: 'opsamling',
      label: t('Workshop.Steps.Step4Label'),
      description: t('Workshop.Steps.Step4Description'),
      subSteps: [],
      selectedSubSteps: [],
    }, */
    {
      name: 'overblikk',
      label: t('Workshop.Steps.Step4bLabel'),
      description: t('Workshop.Steps.Step4bDescription'),
      subSteps: [],
      selectedSubSteps: [],
    },
  ]
}
export const frameworkSettings = [
  {
    frameworkId: 1,
    maxValue: 100,
    steps: 10,
    viewMode: 'layered',
  },
  {
    frameworkId: 4,
    maxValue: 100,
    steps: 6,
    viewMode: 'stacked',
  },
]

export const frameworkLayerSources = [
  {
    id: 1,
    frameworkId: 1,
    label: 'Effect',
    dataSource: 'action-ratings-sum',
    opacity: 1,
    roundToStep: false,
  },
  {
    id: 2,
    frameworkId: 1,
    label: 'Vision',
    dataSource: 'user-ratings',
    opacity: 0.3,
    roundToStep: false,
  },
  {
    id: 4,
    frameworkId: 4,
    label: 'Obj. krit.',
    dataSource: 'question-objective-ratings-sum',
    opacity: 1,
    roundToStep: false,
  },
  {
    id: 3,
    frameworkId: 4,
    label: 'Subj. krit.',
    dataSource: 'question-subjective-ratings-sum',
    opacity: 0.6,
    roundToStep: false,
  },
]

export const frameworks = [
  {
    id: 4,
    title: 'Bokvalitet Test',
    maxValue: 100,
    steps: 6,
    viewMode: 'stacked',
    datasets: [
      {
        values: [20, 40, 80, 60, 100, 20, 100],
        label: 'Ambition',
        dataSource: 'ambition-test',
        opacity: 1,
        roundToStep: false,
      },
      {
        values: [10, 20, 30, 40, 50, 60, 100],
        label: 'Kriterier',
        dataSource: 'kriterier-test',
        opacity: 0.3,
        roundToStep: false,
      },
    ],
  },
]
/*
export const specialOtherSubcats = {
  1: 102,
  2: 103,
  3: 104,
  4: 105,
  5: 106,
  6: 107,
  7: 108,
  8: 109,
  9: 110,
  10: 111,
  11: 112,
  12: 113,
  13: 114,
  14: 115,
}
*/
