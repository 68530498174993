import feathersClient, { makeServicePlugin, BaseModel } from '../../feathers-client'

class StandardQuestionsCollections extends BaseModel {
  // Required for $FeathersVuex plugin to work after production transpile.

  static modelName = 'StandardQuestionsCollections'
  // Define default properties here
  static instanceDefaults () {
    return {
      questionCollectionId: null,
      standardQuestionId: null,
      position: 0,
    }
  }
}
const servicePath = 'standard-questions-collections'
const servicePlugin = makeServicePlugin({
  Model: StandardQuestionsCollections,
  service: feathersClient.service(servicePath),
  servicePath,
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
})

export default servicePlugin
