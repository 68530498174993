<template>
  <div class="">
      <BaseButton
        v-if="menuCollapsed"
        :layout="'icon-only'"
        :icon="'BaseMore'"
        @click="expandMenu"
        class="btn"
        data-cy="headerPanelExpandMenuButton"
      ></BaseButton>
      <div
        class="fixed top-0 bottom-0 pt-20 pl-8 bg-gray-900 transition-all flex flex-col items-start text-white"
        :style="'right: -' + (menuCollapsed ? '100vw' : '0') + ';' + 'width: 100vw;'"
      >
        <div
          class="absolute top-2 right-2 left-2 flex justify-between item-center"
        >
          <div class="text-xl p-2">{{ $t('Shared.menu') }}</div>
          <BaseButton
            v-if="!menuCollapsed"
            :layout="'icon-only'"
            :icon="'BaseClose'"
            @click="menuCollapsed = true"
          />
        </div>
        <Nav class="flex-col mb-8" style=""/>
        <div class="flex flex-col mt-8 gap-8">
          <User style="padding-left: 0; z-index:100;"/>
          <LanguageSelect style="padding-left: 0;"/>
        </div>
      </div>
  </div>
</template>

<script>
import Nav from './Nav'
import User from './User'
import LanguageSelect from './LanguageSelect.vue'
import BaseButton from '@/components/shared/BaseButton.vue'

export default {
  components: {
    Nav,
    User,
    LanguageSelect,
    BaseButton,
  },
  props: {
    textColor: {
      type: String,
      default: 'black',
    },
  },
  data () {
    return {
      menuCollapsed: true,
      menuWidth: 300,
    }
  },
  computed: {
    isAdmin () {
      return this.$store.getters['auth/isAuthenticated'] && this.$store.getters['auth/user'].roleId === 3
    },
    leftEdgePosition () {
      if (!this.menuCollapsed) {
        return 0
      } else {
        return this.menuWidth
      }
    },
  },
  methods: {
    expandMenu () {
      this.menuCollapsed = false
    },
  },
}
</script>

<style scoped>
</style>
