<template>
  <div class="logo-container relative self-stretch">
    <svg v-if="isLink" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 454 73">
      <polygon points="18.28 11.36 7.89 11.36 7.89 56.03 38.25 56.03 38.25 47.14 18.28 47.14 18.28 11.36"/>
      <polygon points="43.46 11.36 43.46 33.7 43.46 56.03 48.66 56.03 53.85 56.03 53.85 33.7 53.85 11.36 48.66 11.36 43.46 11.36"/>
      <polygon points="90.3 38.67 72.01 11.36 61.62 11.36 61.62 56.03 71.57 56.03 71.57 27.23 91.23 56.03 100.25 56.03 100.25 11.36 90.3 11.36 90.3 38.67"/>
      <polygon points="146.4 11.36 134.33 11.36 118.41 30.71 118.41 11.36 108.02 11.36 108.02 56.03 118.41 56.03 118.41 43.96 123.38 38.18 135.39 56.03 147.46 56.03 130.29 30.15 146.4 11.36"/>
      <polygon points="199.87 11.36 192.71 11.36 171.94 33.95 171.94 11.36 165.96 11.36 165.96 56.03 171.94 56.03 171.94 41.41 177.78 35.13 193.71 56.03 200.74 56.03 181.77 30.84 199.87 11.36"/>
      <path d="M216.41,22.87c-8.9,0-15.12,6.84-15.12,16.92s6.22,16.92,15.12,16.92,15.12-6.84,15.12-16.92-6.22-16.92-15.12-16.92ZM216.41,51.93c-5.72,0-9.46-4.73-9.46-12.13s3.73-12.19,9.46-12.19,9.46,4.73,9.46,12.19-3.73,12.13-9.46,12.13Z"/>
      <path d="M272.45,22.87c-5.1,0-8.58,2.86-10.39,5.54-1.62-3.36-4.67-5.54-9.02-5.54s-7.65,2.36-9.39,5.04v-4.42h-5.6v32.54h5.6v-19.47c0-5.29,3.98-8.46,7.71-8.46,4.6,0,6.47,3.42,6.47,7.96v19.97h5.6v-19.47c0-5.29,3.98-8.46,7.71-8.46,4.6,0,6.47,3.42,6.47,7.96v19.97h5.6v-21.46c0-6.91-4.04-11.7-10.76-11.7Z"/>
      <path d="M306.97,22.87c-4.54,0-8.15,2.18-10.08,5.35v-4.73h-5.6v43.73h5.6v-15.93c1.93,3.23,5.54,5.41,10.08,5.41,9.02,0,14.18-7.71,14.18-16.92s-5.16-16.92-14.18-16.92ZM305.97,51.8c-4.6,0-9.21-3.36-9.21-10.82v-2.3c0-7.59,4.6-10.89,9.21-10.89,6.1,0,9.52,4.98,9.52,12.01s-3.42,12.01-9.52,12.01Z"/>
      <path d="M351.73,49.25v-15.86c0-6.97-4.48-10.51-11.76-10.51-6.66,0-11.45,3.79-13,9.08l5.29,1.37c1-3.61,3.42-5.54,7.53-5.54,4.35,0,6.41,2.11,6.41,5.85v1.37l-7.9,1.62c-7.28,1.49-12.32,4.17-12.32,10.64,0,5.85,4.79,9.33,10.7,9.33,4.42,0,8.03-1.68,10.02-4.23.68,3.11,3.55,4.29,8.52,3.67v-4.48c-2.18.5-3.48-.19-3.48-2.3ZM346.19,44.96c0,4.17-4.04,7.09-8.83,7.09-3.55,0-5.85-1.87-5.85-5.04,0-3.92,3.36-5.29,8.34-6.28l6.34-1.37v5.6Z"/>
      <path d="M373.42,36.87c-4.54-1-7.4-1.56-7.4-4.6s2.49-4.79,6.22-4.79c4.11,0,7.15,2.05,8.83,5.29l3.98-3.17c-1.93-3.86-6.72-6.72-12.57-6.72-6.91,0-11.94,4.04-11.94,9.83,0,6.66,5.6,7.84,10.95,9.02,5.35,1.12,8.46,1.62,8.46,5.16s-3.36,5.16-7.15,5.16c-4.23,0-8.21-2.3-9.95-6.1l-4.35,3.17c2.49,5.04,8.4,7.59,14.31,7.59,6.97,0,12.63-4.11,12.63-10.33,0-7.09-6.41-8.21-12.01-9.52Z"/>
      <path d="M403.94,36.87c-4.54-1-7.4-1.56-7.4-4.6s2.49-4.79,6.22-4.79c4.11,0,7.15,2.05,8.83,5.29l3.98-3.17c-1.93-3.86-6.72-6.72-12.57-6.72-6.91,0-11.94,4.04-11.94,9.83,0,6.66,5.6,7.84,10.95,9.02,5.35,1.12,8.46,1.62,8.46,5.16s-3.36,5.16-7.15,5.16c-4.23,0-8.21-2.3-9.95-6.1l-4.35,3.17c2.49,5.04,8.4,7.59,14.31,7.59,6.97,0,12.63-4.11,12.63-10.33,0-7.09-6.41-8.21-12.01-9.52Z"/>
      <path d="M434.17,10.68c-7.34,0-13.38,5.66-13.38,13.37s6.03,13.38,13.38,13.38,13.38-5.6,13.38-13.38-5.97-13.37-13.38-13.37ZM434.17,35.63c-6.35,0-11.38-4.98-11.38-11.57s5.04-11.57,11.38-11.57,11.38,4.91,11.38,11.57-5.04,11.57-11.38,11.57Z"/>
      <path d="M439.96,21.32c0-2.67-1.93-4.17-5.16-4.17h-6.22v13.44h3.11v-4.85h2.18l3.17,4.85h3.48l-3.67-5.54c1.37-.44,3.11-1.49,3.11-3.73ZM434.36,23.37h-2.67v-3.73h2.67c1.43,0,2.36.62,2.36,1.87,0,1.31-.93,1.87-2.36,1.87Z"/>
    </svg>
    <svg v-else id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1366 208.3">
      <path d="M15.19,31.61h38.48c15.23,0,26.68,3.19,34.33,9.57,7.65,6.38,11.48,16.13,11.48,29.24,0,6.56-.86,12.35-2.57,17.38-1.71,5.03-4.23,9.15-7.54,12.35-3.32,3.21-7.34,5.39-12.08,6.56v.98c5.83,1.46,10.6,3.83,14.32,7.11,3.72,3.28,6.47,7.45,8.25,12.52,1.79,5.07,2.68,11.1,2.68,18.09,0,9.33-1.71,17.44-5.14,24.32-3.43,6.89-8.42,12.23-14.98,16.02-6.56,3.79-14.47,5.69-23.72,5.69H15.19V31.61ZM39.24,98.4h14.32c7.43,0,12.9-2.11,16.4-6.34,3.5-4.23,5.25-10.5,5.25-18.8,0-7.07-1.82-12.46-5.47-16.18-3.65-3.72-9.15-5.58-16.51-5.58h-13.99v46.9ZM39.24,117.97v52.91h17.27c7.07,0,12.41-2.24,16.02-6.72,3.61-4.48,5.41-11.28,5.41-20.39,0-5.69-.91-10.42-2.73-14.21-1.82-3.79-4.45-6.67-7.87-8.64-3.43-1.97-7.58-2.95-12.46-2.95h-15.63Z"/>
      <path d="M206.84,130.55c0,9.04-.84,17.38-2.51,25.04-1.68,7.65-4.25,14.32-7.71,20.01-3.46,5.69-7.94,10.11-13.45,13.28-5.5,3.17-12.08,4.76-19.73,4.76s-13.63-1.57-19.02-4.7c-5.39-3.13-9.86-7.52-13.39-13.17-3.54-5.65-6.18-12.32-7.93-20.01-1.75-7.69-2.62-16.09-2.62-25.2,0-12.75,1.51-23.81,4.54-33.18,3.02-9.36,7.74-16.62,14.16-21.76,6.41-5.14,14.61-7.71,24.6-7.71,8.96,0,16.67,2.41,23.12,7.22,6.45,4.81,11.39,11.86,14.81,21.15,3.42,9.29,5.14,20.72,5.14,34.27ZM143.76,130.76c0,9.48.71,17.42,2.13,23.83,1.42,6.41,3.61,11.24,6.56,14.49,2.95,3.24,6.72,4.87,11.31,4.87s8.33-1.6,11.21-4.81c2.88-3.21,4.99-8.02,6.34-14.43,1.35-6.41,2.02-14.47,2.02-24.16s-.67-17.4-2.02-23.78c-1.35-6.38-3.46-11.15-6.34-14.32-2.88-3.17-6.65-4.76-11.31-4.76-7,0-12.06,3.55-15.2,10.66-3.13,7.11-4.7,17.91-4.7,32.41Z"/>
      <path d="M252.21,21.33v79.48c0,4.67-.07,9.24-.22,13.72-.15,4.48-.47,8.95-.98,13.39h.87c1.24-2.84,2.57-5.52,3.99-8.04,1.42-2.51,2.79-4.87,4.1-7.05l25.58-42.64h24.71l-30.39,48.1,32.25,73.14h-24.93l-22.96-55.54-12.03,13.56v41.98h-22.96V21.33h22.96Z"/>
      <path d="M344.69,191.44l-31.59-121.24h23.83l15.2,68.76c1.02,4.3,1.89,8.67,2.62,13.12.73,4.45,1.42,9.11,2.08,13.99h.88c.22-3.13.69-6.72,1.42-10.77.73-4.05,1.64-8.51,2.73-13.39l16.18-71.72h23.72l-31.92,121.24h-25.14Z"/>
      <path d="M453.47,67.9c11.52,0,20.01,3.61,25.47,10.82s8.2,17.78,8.2,31.7v81.01h-17.05l-3.61-16.73h-.55c-2.19,4.08-4.63,7.54-7.32,10.39-2.7,2.84-5.78,4.97-9.24,6.4-3.46,1.42-7.49,2.13-12.08,2.13-6.49,0-11.77-1.73-15.85-5.19-4.08-3.46-7.07-8-8.96-13.61-1.9-5.61-2.84-11.59-2.84-17.93,0-12.32,3.48-21.76,10.44-28.31,6.96-6.56,16.85-10.13,29.68-10.71l14.43-.77v-6.56c0-8.16-1.17-14.17-3.5-18.04-2.33-3.86-6.16-5.79-11.48-5.79-3.43,0-7.09.64-10.99,1.91-3.9,1.28-8.18,3.26-12.85,5.96l-6.67-17.16c5.03-3.13,10.48-5.5,16.34-7.11,5.87-1.6,12.01-2.41,18.42-2.41ZM464.29,133.06l-9.62.55c-7.36.44-12.83,2.51-16.4,6.23-3.57,3.72-5.36,9.11-5.36,16.18,0,6.49,1.15,11.3,3.44,14.43,2.3,3.13,5.52,4.7,9.67,4.7,5.61,0,10.06-2.71,13.34-8.15,3.28-5.43,4.92-12.85,4.92-22.25v-11.7Z"/>
      <path d="M537.1,191.44h-22.96V21.33h22.96v170.11Z"/>
      <path d="M576.02,23.52c4.44,0,7.69,1.28,9.73,3.83,2.04,2.55,3.06,6.16,3.06,10.82s-1.08,8.04-3.23,10.55c-2.15,2.51-5.34,3.77-9.57,3.77s-7.38-1.26-9.68-3.77c-2.3-2.51-3.44-6.03-3.44-10.55,0-4.81,1.09-8.45,3.28-10.93,2.19-2.48,5.47-3.72,9.84-3.72ZM587.28,70.2v121.24h-22.85v-121.24h22.85Z"/>
      <path d="M649.27,173.4c1.89,0,3.88-.24,5.96-.71,2.08-.47,4.14-1.18,6.18-2.13v18.58c-2.7,1.39-5.67,2.48-8.91,3.28-3.24.8-6.72,1.2-10.44,1.2-6.12,0-11.12-1.29-14.98-3.88-3.86-2.59-6.69-6.47-8.47-11.64-1.79-5.17-2.68-11.7-2.68-19.57v-69.2h-11.59v-12.46l13.12-6.01,5.69-27.22h15.74v26.57h21.1v19.13h-21.1v67.67c0,5.54.75,9.66,2.24,12.35,1.49,2.7,4.21,4.04,8.14,4.04Z"/>
      <path d="M714.97,68.12c8.67,0,15.92,2.31,21.76,6.94,5.83,4.63,10.2,11.02,13.12,19.19,2.91,8.16,4.37,17.53,4.37,28.1v14.1h-57.72c.15,12.83,2.28,22.39,6.4,28.7,4.12,6.31,10.4,9.46,18.86,9.46,4.74,0,9.29-.67,13.67-2.02,4.37-1.35,9-3.55,13.88-6.61v19.68c-4.52,2.7-9.29,4.7-14.32,6.01-5.03,1.31-10.42,1.97-16.18,1.97-10.64,0-19.28-2.6-25.91-7.82-6.63-5.21-11.46-12.48-14.49-21.81-3.03-9.33-4.54-20.15-4.54-32.47,0-13.63,1.53-25.14,4.59-34.55,3.06-9.4,7.65-16.56,13.77-21.48s13.7-7.38,22.74-7.38ZM715.3,86.49c-5.61,0-10.02,2.62-13.23,7.87-3.21,5.25-4.99,13.52-5.36,24.82h36.19c0-6.27-.66-11.86-1.97-16.78-1.31-4.92-3.26-8.8-5.85-11.64-2.59-2.84-5.85-4.26-9.78-4.26Z"/>
      <path d="M810.63,173.4c1.89,0,3.88-.24,5.96-.71,2.08-.47,4.14-1.18,6.18-2.13v18.58c-2.7,1.39-5.67,2.48-8.91,3.28-3.24.8-6.72,1.2-10.44,1.2-6.12,0-11.12-1.29-14.98-3.88-3.86-2.59-6.69-6.47-8.47-11.64-1.79-5.17-2.68-11.7-2.68-19.57v-69.2h-11.59v-12.46l13.12-6.01,5.68-27.22h15.74v26.57h21.1v19.13h-21.1v67.67c0,5.54.75,9.66,2.24,12.35,1.49,2.7,4.21,4.04,8.14,4.04Z"/>
      <path d="M899.18,157.66c0,7.73-1.44,14.27-4.32,19.62-2.88,5.36-7.03,9.42-12.46,12.19-5.43,2.77-11.97,4.15-19.62,4.15-5.76,0-11.12-.62-16.07-1.86-4.96-1.24-9.44-2.88-13.45-4.92v-22.85c3.57,2.62,7.83,4.85,12.79,6.67,4.96,1.82,9.98,2.73,15.09,2.73s8.62-1.27,11.21-3.83c2.59-2.55,3.88-6.16,3.88-10.82,0-2.77-.53-5.23-1.58-7.38-1.06-2.15-2.92-4.3-5.58-6.45-2.66-2.15-6.43-4.5-11.31-7.05-4.96-2.91-9.29-6.08-13.01-9.51-3.72-3.42-6.58-7.36-8.58-11.81-2-4.44-3.01-9.66-3.01-15.63,0-10.06,3.21-18.07,9.62-24.05,6.41-5.98,15.01-8.96,25.8-8.96,5.54,0,10.75.73,15.63,2.19,4.88,1.46,9.58,3.61,14.1,6.45l-7.11,18.04c-3.28-2.26-6.76-4.1-10.44-5.52-3.68-1.42-7.42-2.13-11.21-2.13-4.45,0-7.93,1.19-10.44,3.55-2.51,2.37-3.77,5.56-3.77,9.57,0,2.7.53,5.03,1.58,7,1.06,1.97,2.95,3.99,5.69,6.07,2.73,2.08,6.5,4.57,11.31,7.49,5.03,3.06,9.42,6.25,13.17,9.57,3.75,3.32,6.7,7.16,8.86,11.53,2.15,4.37,3.22,9.69,3.22,15.96Z"/>
      <path d="M966.09,68.01c1.6,0,3.21.13,4.81.38,1.6.26,3.39.67,5.36,1.26l-2.62,24.16c-1.38-.58-2.88-.98-4.48-1.2-1.6-.22-3.32-.33-5.14-.33-3.35,0-6.36.89-9.02,2.68-2.66,1.79-4.94,4.34-6.83,7.65-1.9,3.32-3.33,7.2-4.32,11.64-.98,4.45-1.44,9.33-1.37,14.65v62.53h-22.96v-121.24h17.71l3.28,20.66h1.2c1.68-4.23,3.68-8.07,6.01-11.53,2.33-3.46,5.03-6.21,8.09-8.25,3.06-2.04,6.49-3.06,10.28-3.06Z"/>
      <path d="M1073.45,130.55c0,9.04-.84,17.38-2.51,25.04-1.68,7.65-4.25,14.32-7.71,20.01-3.46,5.69-7.94,10.11-13.45,13.28-5.5,3.17-12.08,4.76-19.73,4.76s-13.63-1.57-19.02-4.7c-5.39-3.13-9.86-7.52-13.39-13.17-3.54-5.65-6.18-12.32-7.93-20.01-1.75-7.69-2.62-16.09-2.62-25.2,0-12.75,1.51-23.81,4.54-33.18,3.02-9.36,7.74-16.62,14.16-21.76,6.41-5.14,14.61-7.71,24.6-7.71,8.96,0,16.67,2.41,23.12,7.22,6.45,4.81,11.39,11.86,14.81,21.15,3.42,9.29,5.14,20.72,5.14,34.27ZM1010.37,130.76c0,9.48.71,17.42,2.13,23.83,1.42,6.41,3.61,11.24,6.56,14.49,2.95,3.24,6.72,4.87,11.32,4.87s8.33-1.6,11.21-4.81c2.88-3.21,4.99-8.02,6.34-14.43,1.35-6.41,2.02-14.47,2.02-24.16s-.67-17.4-2.02-23.78c-1.35-6.38-3.46-11.15-6.34-14.32-2.88-3.17-6.65-4.76-11.32-4.76-7,0-12.06,3.55-15.2,10.66-3.13,7.11-4.7,17.91-4.7,32.41Z"/>
      <path d="M1155.11,157.66c0,7.73-1.44,14.27-4.32,19.62-2.88,5.36-7.03,9.42-12.46,12.19-5.43,2.77-11.97,4.15-19.62,4.15-5.76,0-11.12-.62-16.07-1.86-4.96-1.24-9.44-2.88-13.45-4.92v-22.85c3.57,2.62,7.83,4.85,12.79,6.67,4.96,1.82,9.98,2.73,15.09,2.73s8.62-1.27,11.21-3.83c2.59-2.55,3.88-6.16,3.88-10.82,0-2.77-.53-5.23-1.58-7.38-1.06-2.15-2.92-4.3-5.58-6.45-2.66-2.15-6.43-4.5-11.31-7.05-4.96-2.91-9.29-6.08-13.01-9.51-3.72-3.42-6.58-7.36-8.58-11.81-2-4.44-3.01-9.66-3.01-15.63,0-10.06,3.21-18.07,9.62-24.05,6.41-5.98,15.01-8.96,25.8-8.96,5.54,0,10.75.73,15.63,2.19,4.88,1.46,9.58,3.61,14.1,6.45l-7.11,18.04c-3.28-2.26-6.76-4.1-10.44-5.52-3.68-1.42-7.42-2.13-11.21-2.13-4.45,0-7.93,1.19-10.44,3.55-2.51,2.37-3.77,5.56-3.77,9.57,0,2.7.53,5.03,1.58,7,1.06,1.97,2.95,3.99,5.69,6.07,2.73,2.08,6.5,4.57,11.31,7.49,5.03,3.06,9.42,6.25,13.17,9.57,3.75,3.32,6.7,7.16,8.86,11.53,2.15,4.37,3.22,9.69,3.22,15.96Z"/>
      <path d="M1211.74,68.12c8.67,0,15.92,2.31,21.76,6.94,5.83,4.63,10.2,11.02,13.12,19.19,2.91,8.16,4.37,17.53,4.37,28.1v14.1h-57.72c.15,12.83,2.28,22.39,6.4,28.7,4.12,6.31,10.4,9.46,18.86,9.46,4.74,0,9.29-.67,13.67-2.02,4.37-1.35,9-3.55,13.88-6.61v19.68c-4.52,2.7-9.29,4.7-14.32,6.01-5.03,1.31-10.42,1.97-16.18,1.97-10.64,0-19.28-2.6-25.91-7.82-6.63-5.21-11.46-12.48-14.49-21.81-3.03-9.33-4.54-20.15-4.54-32.47,0-13.63,1.53-25.14,4.59-34.55,3.06-9.4,7.65-16.56,13.77-21.48s13.7-7.38,22.74-7.38ZM1212.07,86.49c-5.61,0-10.02,2.62-13.23,7.87-3.21,5.25-4.99,13.52-5.36,24.82h36.19c0-6.27-.66-11.86-1.97-16.78-1.31-4.92-3.26-8.8-5.85-11.64-2.59-2.84-5.85-4.26-9.78-4.26Z"/>
      <path d="M1321.94,67.9c9.91,0,17.47,3.54,22.68,10.6,5.21,7.07,7.82,17.57,7.82,31.49v81.45h-22.96v-75.54c0-9.11-1.06-15.98-3.17-20.61-2.12-4.63-5.76-6.94-10.93-6.94-7,0-12.01,3.24-15.03,9.73-3.03,6.49-4.54,17.24-4.54,32.25v61.11h-22.96v-121.24h18.26l2.41,16.51h1.53c1.75-3.94,3.94-7.31,6.56-10.11,2.62-2.8,5.65-4.96,9.07-6.45,3.42-1.49,7.18-2.24,11.26-2.24Z"/>
    </svg>
  </div>
</template>

<script>
export default {
  computed: {
    isLink () {
      return ['https://app.linkkompass.ai', 'https://kompass.linkarkitektur.com'].includes(window.location.origin)
    },
  },
}
</script>

<style scoped>
.logo-container {
  position: relative;
  overflow: hidden;
  width: 220px;
}
</style>
