/* eslint-disable no-unused-vars */
import * as d3 from 'd3'
import { createChartConfig } from './createChartConfig.js'
import { createChartLayers } from './createChartLayers.js'
/**
 * TODO:
 */

export function createRoseChartD3 (containerId, categories, datasets, options = {}) {
  const chart = createChartConfig(categories, datasets, options)
  chart.container = d3.select('#' + containerId)

  // setup svg layers
  createChartLayers(chart)
  function drawDataLayers () {
    const angleStep = (Math.PI * 2) / chart.labels.length
    let startAngle = 0 - (angleStep / 2)
    let endAngle = startAngle + angleStep
    for (let datasetIndex = 0; datasetIndex < chart.dataLayers.length; datasetIndex++) {
      const dataLayer = chart.dataLayers[datasetIndex]
      dataLayer.selectAll('*').remove()
      for (let i = 0; i < chart.labels.length; i++) {
        drawDataRosePetal(startAngle, endAngle, i, datasetIndex)
        const opaqueDatasetValue = chart.datasets[0].values[i]
        const transparentDatasetValue = chart.datasets[1].values[i]
        if (datasetIndex === 0 && opaqueDatasetValue > transparentDatasetValue && chart.viewMode !== 'stacked') {
          drawDataLine(startAngle, endAngle, i, opaqueDatasetValue, chart.markerLineColor)
        }
        if (datasetIndex === 1 && (opaqueDatasetValue / chart.maxValue) > 1) {
          drawDataLine(startAngle, endAngle, i, chart.maxValue, 'rgba(255, 0, 0, 1)')
        }
        startAngle = endAngle
        endAngle = endAngle + angleStep
      }
    }
  }
  function drawDataRosePetal (startAngle, endAngle, categoryIndex, datasetIndex) {
    const dataLayer = chart.dataLayers[datasetIndex]
    const totalSegmentsRadius = chart.outerRadius - chart.innerRadius

    // Calculate starting radius based on view mode
    let innerRadius = chart.innerRadius
    if (chart.viewMode === 'stacked' && datasetIndex > 0) {
      // In stacked mode, start from previous dataset's value
      const previousValue = chart.datasets[datasetIndex - 1].values[categoryIndex]
      // Round to nearest step to avoid floating point issues
      innerRadius += totalSegmentsRadius * (previousValue / chart.maxValue)
    }

    const currentValue = chart.datasets[datasetIndex].values[categoryIndex]

    const outerRadius = innerRadius + (totalSegmentsRadius * Math.min(currentValue / chart.maxValue, 1))
    const valueSegment = drawSegment(
      dataLayer,
      chart.roseCenter,
      { innerRadius, outerRadius, startAngle, endAngle },
      { segmentValue: currentValue, categoryIndex },
    )

    valueSegment.style('fill', chart.colors[categoryIndex])
    valueSegment.style('fill-opacity', chart.datasets[datasetIndex].opacity)
    // valueSegment.style('stroke', chart.contextBackground)
    // valueSegment.style('stroke-width', chart.gap)
  }

  function drawDataRosePetalSegmented (startAngle, endAngle, categoryIndex, datasetIndex) {
    const dataLayer = chart.dataLayers[datasetIndex]
    const totalSegmentsRadius = chart.outerRadius - chart.innerRadius
    const segmentRadius = totalSegmentsRadius / chart.steps

    // Calculate starting radius based on view mode
    let innerRadius = chart.innerRadius
    let previousValue = 0
    let previousValueInSteps = 0
    if (chart.viewMode === 'stacked' && datasetIndex > 0) {
      // In stacked mode, start from previous dataset's value
      previousValue = chart.datasets[datasetIndex - 1].values[categoryIndex]
      // Round to nearest step to avoid floating point issues
      previousValueInSteps = previousValue / (chart.maxValue / chart.steps)
      innerRadius += segmentRadius * previousValueInSteps
    }

    let outerRadius = innerRadius + segmentRadius
    let segmentsToDraw = chart.steps
    if (chart.viewMode === 'stacked' && datasetIndex > 0) {
      segmentsToDraw = chart.steps - previousValueInSteps
    }
    for (let i = 1; i <= segmentsToDraw; i++) {
      const segmentValue = chart.maxValue / chart.steps * i
      const effectiveValue = chart.datasets[datasetIndex].values[categoryIndex]
      const effectiveValueInSteps = Math.round(effectiveValue / (chart.maxValue / chart.steps))
      let effectiveValueRounded = effectiveValueInSteps * (chart.maxValue / chart.steps)
      if (chart.viewMode === 'stacked' && datasetIndex > 0) {
        // Add previous dataset's value in stacked mode
        effectiveValueRounded += previousValueInSteps * (chart.maxValue / chart.steps)
      }

      const valueSegment = drawSegment(
        dataLayer,
        chart.roseCenter,
        { innerRadius, outerRadius, startAngle, endAngle },
        { segmentValue, categoryIndex },
      )

      const segmentColor = segmentValue <= effectiveValueRounded
        ? chart.colors[categoryIndex]
        : chart.arcBackgroundColor

      valueSegment.style('fill', segmentColor)
      valueSegment.style('stroke', chart.contextBackground)
      valueSegment.style('stroke-width', chart.gap)
      valueSegment.style('fill-opacity', chart.datasets[datasetIndex].opacity)

      innerRadius += segmentRadius
      outerRadius += segmentRadius
    }
  }

  function drawOtherLayers () {
    chart.backgroundLayer.selectAll('*').remove()
    chart.interactionLayer.selectAll('*').remove()
    chart.gridLayer.selectAll('*').remove()
    // Append an SVG container
    /*
    Experiments in trying to use mask or clipping-path instead
    of border to greate the gaps between categories and segments
    I could not get it to work. Mask seems to work on shape, but
    not on arcs.

    const newSvg = chart.bottomLayer
      .append('svg')
      .attr('width', chart.width)
      .attr('height', chart.height)

    newSvg
      .append('circle')
      .attr('cx', chart.width / 2)
      .attr('cy', chart.height / 2)
      .attr('r', 100)
      .attr('fill', 'black')

    // Define the mask
    newSvg.append('defs')
      .append('mask')
      .attr('maskUnits', 'userSpaceOnUse')
      .attr('id', 'arcMask') // ID to reference the mask
      .call(mask => {
        // Background (black = fully masked)
        mask.append('rect')
          .attr('x', 0)
          .attr('y', 0)
          .attr('width', chart.width)
          .attr('height', chart.height)
          .attr('fill', 'black')

        // White circle for visible area
        mask.append('circle')
          .attr('cx', chart.width / 2)
          .attr('cy', chart.height / 2)
          .attr('r', 100)
          .attr('fill', 'white')
          .attr('fill-opacity', 0.5)
      })

    // Draw an arc and apply the mask
    newSvg.append('path')
      .attr('transform', `translate(${chart.width / 2},${chart.height / 2})`)
      .attr('d', d3.arc()({
        innerRadius: chart.innerRadius,
        outerRadius: chart.outerRadius,
        startAngle: 0,
        endAngle: Math.PI / 2,
      }))
      .attr('fill', 'red')
      .attr('mask', 'url(#arcMask)') // Apply the mask here

    newSvg.append('circle')
      .attr('cx', chart.width / 2)
      .attr('cy', chart.height / 2)
      .attr('r', 100)
      .attr('fill', 'green')
      .attr('mask', 'url(#arcMask)') */

    // angle divide is not used, because it was making a triangular gap
    // const angleDivide = chart.gap / chart.outerRadius
    // let endAngle = angleStep - angleDivide
    const angleStep = (Math.PI * 2) / chart.labels.length
    let startAngle = 0 - (angleStep / 2)
    let endAngle = startAngle + angleStep

    for (let i = 0; i < chart.labels.length; i++) {
      drawRosePetal(startAngle, endAngle, i)
      startAngle = endAngle
      endAngle = endAngle + angleStep
      // startAngle = startAngle + angleStep
      // endAngle = startAngle + angleStep - angleDivide
    }
    addDragBehavior(chart.interactionLayer)
  }

  function drawRoseChart () {
    drawDataLayers()
    drawOtherLayers()
  }

  function drawDataLine (startAngle, endAngle, categoryIndex, value, color) {
    color = color || chart.markerLineColor
    const totalSegmentsRadius = chart.outerRadius - chart.innerRadius
    const segmentRadius = totalSegmentsRadius / chart.steps
    const valueInSegments = value / (chart.maxValue / chart.steps)
    const radius = chart.innerRadius + segmentRadius * valueInSegments
    drawSegment(
      chart.dataLayers[1],
      chart.roseCenter,
      { innerRadius: radius - 1, outerRadius: radius + 1, startAngle, endAngle },
      { segmentValue: null, categoryIndex },
    ).style('fill', color)
  }

  function drawRosePetal (startAngle, endAngle, categoryIndex) {
    const totalSegmentsRadius = chart.outerRadius - chart.innerRadius
    const segmentRadius = totalSegmentsRadius / chart.steps

    let innerRadius = chart.innerRadius
    let outerRadius = innerRadius + segmentRadius

    for (let i = 1; i <= chart.steps; i++) {
      const segmentValue = chart.maxValue / chart.steps * i

      const gridLayerSegment = drawSegment(
        chart.gridLayer,
        chart.roseCenter,
        { innerRadius, outerRadius, startAngle, endAngle },
        { segmentValue, categoryIndex },
      )
      gridLayerSegment.style('fill', 'rgba(0, 0, 0, 0)')
      gridLayerSegment.style('stroke', chart.contextBackground)
      gridLayerSegment.style('stroke-width', chart.gap)

      const topLayerSegment = drawSegment(
        chart.interactionLayer,
        chart.roseCenter,
        { innerRadius, outerRadius, startAngle, endAngle },
        { segmentValue, categoryIndex },
      )
      topLayerSegment.style('fill', 'rgba(0, 0, 0, 0)')
      topLayerSegment.style('stroke', 'rgba(0, 0, 0, 0)')
      topLayerSegment.style('stroke-width', chart.gap)

      handleValueClick(topLayerSegment)
      handleValueHover(topLayerSegment, chart.interactionLayer)

      innerRadius += segmentRadius
      outerRadius += segmentRadius
    }

    const categoryBackgroundSegment = drawCategoryBackground(categoryIndex, startAngle, endAngle)
    drawCategoryIcon(categoryIndex, startAngle)
    const categoryInteractorSegment = drawCategoryInteractor(categoryIndex, startAngle, endAngle)
    handleCategoryHover(categoryInteractorSegment, categoryBackgroundSegment)
    handleCategoryClick(categoryInteractorSegment, startAngle)
  }

  function drawSegment (svg, center, arcOptions, data) {
    return svg.append('path')
      .attr('transform', `translate(${center.x},${center.y})`)
      .attr('d', d3.arc()(arcOptions))
      .datum({ value: data.segmentValue, categoryIndex: data.categoryIndex })
  }

  function drawCategoryArc (layer, categoryIndex, startAngle, endAngle, bgColor) {
    const innerRadius = chart.outerRadius
    const outerRadius = innerRadius + chart.iconSize + (chart.iconPadding * 2)
    const categoryIconSegment = drawSegment(
      layer,
      chart.roseCenter,
      { innerRadius, outerRadius, startAngle, endAngle },
      { segmentValue: null, categoryIndex },
    )
    categoryIconSegment.style('fill', bgColor)
    categoryIconSegment.style('stroke', chart.contextBackground)
    categoryIconSegment.style('stroke-width', chart.gap)
    return categoryIconSegment
  }

  function drawCategoryBackground (categoryIndex, startAngle, endAngle) {
    return drawCategoryArc(chart.backgroundLayer, categoryIndex, startAngle, endAngle, chart.categoryBackgroundColor)
  }

  function drawCategoryInteractor (categoryIndex, startAngle, endAngle) {
    return drawCategoryArc(chart.interactionLayer, categoryIndex, startAngle, endAngle, 'rgba(0, 0, 0, 0)')
  }

  async function drawCategoryIcon (categoryIndex, startAngle) {
    const angleStep = (Math.PI * 2) / chart.labels.length
    startAngle = startAngle - Math.PI / 2 + angleStep / 2
    const { x, y } = chart.roseCenter
    const iconX = x + ((chart.outerRadius + chart.iconPadding + chart.iconSize / 2) * Math.cos(startAngle)) - chart.iconSize / 2
    const iconY = y + ((chart.outerRadius + chart.iconPadding + chart.iconSize / 2) * Math.sin(startAngle)) - chart.iconSize / 2
    // reset draw position to 0, 0
    // then draw the icon and place just above the last segment and rotate it to match the angle of the last segment
    if (chart.iconUrls && chart.iconUrls[categoryIndex]) {
      // Read SVG data from iconUrl
      const iconSvg = await d3.xml(chart.iconUrls[categoryIndex])

      const importedSvg = iconSvg.documentElement

      const inheritedFill = importedSvg.getAttribute('fill') || 'none'
      const inheritedStroke = importedSvg.getAttribute('stroke') || 'none'
      const inheritedStrokeWidth = importedSvg.getAttribute('stroke-width') || 'none'
      const inheritedStrokeMiterlimit = importedSvg.getAttribute('stroke-miterlimit') || 'none'

      if (!importedSvg.querySelector('style')) {
        const styleEl = document.createElement('style')
        styleEl.textContent = '.cls-1 { fill: none; stroke: #000; stroke-miterlimit: 10; stroke-width: 1.5px; }'
        importedSvg.insertBefore(styleEl, importedSvg.firstChild)
      }

      // Create a group container and position/rotate it
      const iconGroup = chart.backgroundLayer.append('g')
        .attr('transform', `translate(${iconX}, ${iconY}) rotate(${((startAngle + Math.PI / 2) * 180 / Math.PI)}, ${chart.iconSize / 2}, ${chart.iconSize / 2})`)

      // If the imported node is an <svg>, extract its children
      let fragment
      if (importedSvg.nodeName.toLowerCase() === 'svg') {
        fragment = document.createDocumentFragment()
        while (importedSvg.firstChild) {
          const child = importedSvg.firstChild
          if (child.nodeType === Node.ELEMENT_NODE && !child.hasAttribute('fill') && !['rect', 'circle', 'ellipse', 'line', 'path', 'polygon', 'polyline', 'polygon'].includes(child.nodeName.toLowerCase())) {
            child.setAttribute('fill', inheritedFill)
          }
          if (child.nodeType === Node.ELEMENT_NODE && !child.hasAttribute('stroke') && !['rect', 'circle', 'ellipse', 'line', 'path', 'polygon', 'polyline', 'polygon'].includes(child.nodeName.toLowerCase())) {
            child.setAttribute('stroke', inheritedStroke)
          }
          if (child.nodeType === Node.ELEMENT_NODE && !child.hasAttribute('stroke-width') && !['rect', 'circle', 'ellipse', 'line', 'path', 'polygon', 'polyline', 'polygon'].includes(child.nodeName.toLowerCase())) {
            child.setAttribute('stroke-width', inheritedStrokeWidth)
          }
          if (child.nodeType === Node.ELEMENT_NODE && !child.hasAttribute('stroke-miterlimit') && !['rect', 'circle', 'ellipse', 'line', 'path', 'polygon', 'polyline', 'polygon'].includes(child.nodeName.toLowerCase())) {
            child.setAttribute('stroke-miterlimit', inheritedStrokeMiterlimit)
          }
          fragment.appendChild(child)
        }
      } else {
        fragment = document.importNode(importedSvg, true)
      }

      // Append the inlined content into the group
      iconGroup.node().appendChild(fragment)

      // Optionally adjust scaling if the imported icon has a viewBox
      const viewBox = importedSvg.getAttribute('viewBox')
      if (viewBox) {
        const [minX, minY, width, height] = viewBox.split(' ').map(Number)
        const scale = chart.iconSize / Math.max(width, height)
        iconGroup.attr('transform', `translate(${iconX}, ${iconY}) rotate(${((startAngle + Math.PI / 2) * 180 / Math.PI)}, ${chart.iconSize / 2}, ${chart.iconSize / 2}) scale(${scale})`)
      }
    } else {
      // if no icon url, draw a number
      chart.backgroundLayer
        .append('text')
        .attr('x', iconX + chart.iconSize / 2)
        .attr('y', iconY + chart.iconSize / 2)
        .attr('text-anchor', 'middle')
        .attr('dominant-baseline', 'middle')
        .text((categoryIndex + 1) + '.')
        .attr('transform', `rotate(${((startAngle + Math.PI / 2) * (180 / (Math.PI)))}, ${iconX + chart.iconSize / 2}, ${iconY + chart.iconSize / 2})`)
    }
  }

  function drawCategoryInfo (categoryIndex) {
    hideInfo()
    const { x, y } = chart.roseCenter
    const fadeOutLayers = [...chart.dataLayers, chart.gridLayer]
    fadeOutLayers.forEach((layer) => {
      layer.style('opacity', 0.2)
    })
    // Draw the white filled circle
    const infoGroup = chart.infoLayer.append('g')
      .attr('transform', `translate(${x},${y})`)

    /* infoGroup.append('circle')
      .attr('r', circleRadius)
      .attr('fill', 'white')
      .attr('fill-opacity', 0.7) */

    // Add the text elements
    const headline = chart.labels[categoryIndex]
    // Create foreignObject initially positioned at y=0
    const width = chart.outerRadius + 100
    const foreignObject = infoGroup.append('foreignObject')
      .attr('x', -width / 2)
      .attr('y', 0)
      .attr('width', width)
      .attr('height', chart.outerRadius) // Initial height - will adjust later
    // Add the div with the text
    const div = foreignObject.append('xhtml:div')
      .style('text-align', 'center')
      .style('color', 'black')
      .style('font-size', '16px')
      .text(headline)

    // Get the computed height of the div
    const divHeight = div.node().getBoundingClientRect().height

    // Adjust the foreignObject position and height
    foreignObject
      .attr('y', -divHeight) // Move up by the div's height
      .attr('height', divHeight) // Set exact height

    chart.datasets.forEach((dataset, i) => {
      infoGroup.append('text')
        .attr('text-anchor', 'middle')
        .attr('dy', ((i + 1) * 1.5) + 'em')
        .attr('font-size', '12px') // Adjust font size as needed
        .attr('fill', 'black')
        .text(`${dataset.label}: ${dataset.values[categoryIndex]}%`)
    })
  }
  function hideInfo () {
    if (chart.infoLayer && chart.infoLayer.selectAll('*').size() > 0) {
      chart.infoLayer.selectAll('*').remove()
    }
    const fadeOutLayers = [...chart.dataLayers, chart.gridLayer]
    fadeOutLayers.forEach((layer) => {
      layer.style('opacity', 1)
    })
  }

  function createCategoryClickHandler (startAngle) {
    return function (event) {
      if (!chart.allowCategoryClick) return
      // This function rotates the layers to the angle of the clicked icon
      // TODO: emit an event to the parent to notify that the icon was clicked
      event.stopPropagation()
      const angleStep = (Math.PI * 2) / chart.labels.length
      const angle = -1 * (startAngle + (angleStep / 2))
      const targetAngleDegrees = angle * (180 / Math.PI)

      // Get current rotation angle from transform attribute
      const amplitude = 1
      const durationFactor = 15
      const duration = getDuration(chart.interactionLayer, targetAngleDegrees, durationFactor)
      const period = duration / 2000

      const layers = [chart.backgroundLayer, chart.interactionLayer, chart.gridLayer, ...chart.dataLayers]
      layers.forEach((layer) => {
        layer.transition()
          .duration(duration)
          .ease(d3.easeElasticOut.period(period).amplitude(amplitude))
          .attr('transform', `rotate(${targetAngleDegrees})`)
      })
    }
  }

  function getDuration (layer, targetAngleDegrees, durationFactor) {
    const currentTransform = layer.attr('transform') || 'rotate(0)'
    const currentAngleDegrees = parseFloat(currentTransform.match(/rotate\(([-\d.]+)\)/)?.[1] || 0)
    let angleDifference = ((targetAngleDegrees - currentAngleDegrees + 180) % 360) - 180
    if (angleDifference < -180) angleDifference += 360
    return Math.min(2000, Math.max(800, Math.abs(angleDifference) * durationFactor))
  }

  function handleValueHover (segment, svg) {
    if (!chart.canRate || chart.viewMode === 'stacked') return
    segment.on('mouseover', function (event, hoveredSegementData) {
      svg.selectAll('path')
        .filter((filteredSegmentData) => (
          filteredSegmentData.value !== null &&
          filteredSegmentData.value <= hoveredSegementData.value &&
          filteredSegmentData.categoryIndex === hoveredSegementData.categoryIndex
        )) // get all arcs with value less than or equal to the hovered arc and where the attribute data-petal index is the same as the event.target
        .transition()
        .duration(250)
        .style('fill', chart.arcHoverColor)
        .style('stroke', chart.contextBackground)
    })
    segment.on('mouseout', function () {
      svg.selectAll('path')
        .transition()
        .duration(150)
        .style('fill', 'rgba(0, 0, 0, 0)')
        .style('stroke', 'rgba(0, 0, 0, 0)')
    })
  }

  function handleValueClick (segment) {
    if (!chart.canRate || chart.viewMode === 'stacked') return
    segment.on('click', function (event, clickedSegmentData) {
      const newValue = clickedSegmentData.value
      chart.onRate(newValue, clickedSegmentData.categoryIndex)
    })
  }

  function handleCategoryHover (categorySegment, categoryBackgroundSegment) {
    if (!chart.canHover) return
    let isHovering = false // Add state to track hover status

    categorySegment.on('mouseenter', function () {
      if (isHovering) return
      isHovering = true
      const categoryIndex = d3.select(this).datum().categoryIndex
      drawCategoryInfo(categoryIndex)
    })
    categorySegment.on('mouseover', function () {
      // get data of the segment
      categoryBackgroundSegment
        .transition()
        .duration(250)
        .style('fill', chart.categoryHoverColor)
    })
    categorySegment.on('mouseout', function () {
      isHovering = false
      hideInfo()
      categoryBackgroundSegment
        .transition()
        .duration(150)
        .style('fill', chart.categoryBackgroundColor)
    })
  }
  function handleCategoryClick (categorySegment, startAngle) {
    if (!chart.allowCategoryClick) return
    const categoryClickHandler = createCategoryClickHandler(startAngle)
    categorySegment
      .style('cursor', 'pointer') // Add cursor pointer to indicate clickable
      .on('click', categoryClickHandler)
  }

  function addDragBehavior (interactionLayer) {
    if (!chart.canRate) return
    const drag = d3.drag()
      .on('start', dragStarted)
      .on('drag', dragged)
      .on('end', dragEnded)

    interactionLayer.call(drag)
    let draggedCategoryIndex = null
    function dragStarted (event, d) {
      const element = document.elementFromPoint(event.sourceEvent.clientX, event.sourceEvent.clientY)
      const draggedPath = d3.select(element)
      // if no path or datum, cancel drag
      if (!draggedPath || !draggedPath.datum()) {
        return
      }
      draggedCategoryIndex = draggedPath.datum().categoryIndex
    }

    function dragged (event, d) {
      const { x, y } = event
      // Now d will contain the datum with categoryIndex and value
      const categoryIndex = draggedCategoryIndex

      const stepSize = chart.maxValue / chart.steps
      const distanceFromCenter = Math.sqrt(Math.pow(x - chart.roseCenter.x, 2) + Math.pow(y - chart.roseCenter.y, 2))
      const totalRadiusSpan = chart.outerRadius - chart.innerRadius
      const proportionalValue = (distanceFromCenter - chart.innerRadius) / totalRadiusSpan
      // the + (stepSize / 2) is a hack to make it feel more accurate
      const scaledValue = (chart.maxValue * proportionalValue) + (stepSize / 2)
      const steppedValue = (Math.round(scaledValue / stepSize) * stepSize)
      const newValue = Math.max(0, Math.min(chart.maxValue, steppedValue))

      if (chart.canRate) {
        chart.onRate(newValue, categoryIndex)
      }
    }

    function dragEnded (event) {
      // Finalize the value or perform any cleanup if necessary
    }
  }

  /* function getAdjustedCenter (center, radius, startAngle, angleStep, angleDivide) {
    const angle = (startAngle - Math.PI / 2) + ((angleStep - angleDivide) / 2)

    // opposite = hypotenuse * sin(angle)
    const halfChordLength = radius * Math.sin(angleDivide / 2)
    // hypotenuse = opposite / sin(angle)
    const adjustedRadius = halfChordLength / Math.sin(angleStep / 2) + 5
    const adjustedX = center.x + (adjustedRadius * Math.cos(angle))
    const adjustedY = center.y + (adjustedRadius * Math.sin(angle))
    return { x: adjustedX, y: adjustedY }
  } */

  function on (eventName, callback) {
    if (eventName === 'rating') chart.onRate = callback
  }
  function updateDataValues (datasets) {
    chart.datasets = datasets
    drawDataLayers()
  }
  function resize () {
    chart.outerRadius = (chart.height / 2) - chart.iconSize - (chart.iconPadding * 2)
    chart.roseCenter = { x: chart.width / 2, y: chart.height / 2 }
    drawRoseChart()
  }
  // Expose public methods
  return {
    updateDataValues,
    resize,
    on,
    // Additional exposed methods can be added here
  }
}
