export function createChartLayers (chart) {
  chart.backgroundLayer = chart.container
    .append('svg')
    .attr('width', chart.width)
    .attr('height', chart.height)

  chart.datasets.forEach((dataset) => {
    const layer = chart.container
      .append('svg')
      .style('position', 'absolute')
      .style('top', '0')
      .attr('width', chart.width)
      .attr('height', chart.height)
    chart.dataLayers.push(layer)
  })

  chart.gridLayer = chart.container
    .append('svg')
    .style('position', 'absolute')
    .style('top', '0')
    .attr('width', chart.width)
    .attr('height', chart.height)

  chart.infoLayer = chart.container
    .append('svg')
    .style('position', 'absolute')
    .style('top', '0')
    .style('left', '0')
    .attr('width', chart.width)
    .attr('height', chart.height)

  chart.interactionLayer = chart.container
    .append('svg')
    .style('position', 'absolute')
    .style('top', '0')
    .attr('width', chart.width)
    .attr('height', chart.height)
}
