<template>
  <div class="h-screen" :class="{ 'bg-gray-100': shouldChangeBodyBg }">
    <BaseLoader v-if="loading && !error"/>
    <BaseError v-if="error" :errorMessage="error" />
    <router-view v-if="!loading && !error"/>
    <div class="hidden cursor-grab text-black text-white bg-gray-300 bg-gray-500 bg-blue-500 ring text-green-500 bg-yellow-500 bg-green-500"></div>
  </div>
</template>

<script>
import BaseError from './components/shared/BaseError.vue'
import BaseLoader from './components/shared/BaseLoader.vue'
import systemTexts from '@/i18n/main'
import parselang from '@/i18n/parseLangObj'
import languages from '@/i18n/languages'

export default {
  components: { BaseLoader, BaseError },
  data () {
    return {
      loading: false,
      error: null,
    }
  },
  async created () {
    // Set title in head
    if (window.location.origin === 'https://bokvalitetsrosen.no') {
      document.title = 'Bokvalitetsrosen'
    }
    this.loading = true
    // this.$store.dispatch('setCmsLocale', navigator.languages[0].replace('-', '_'))
    // Check language in local storage
    const userLang = window.localStorage.getItem('user-lang')
    if (userLang) {
      this.$store.dispatch('setCmsLocale', userLang)
      this.$i18n.locale = userLang
    } else {
      this.$store.dispatch('setCmsLocale', 'no_NO')
    }
    try {
      const result = await this.$store.dispatch('frontend-texts/find')
      if (result[0]) {
        const dbTexts = result[0].content
        parselang(dbTexts)
        languages.forEach(lang => {
          systemTexts[lang.value] = dbTexts[lang.value]
        })
      }
      // await this.$store.dispatch('refreshCatsAndSubCats')
      this.loading = false
    } catch (error) {
      /*
      if (error.code === 401 && window.confirm('Du er ikke logget ind. Tryk ok for at gå til log ind-siden')) {
        this.$router.push('/login')
        this.loading = false
      }
      */
      this.loading = false
      // this.error = this.$t('errors.general') + ' (' + error.code + ')'
    }
  },
  computed: {
    isAuthenticated () {
      return this.$store.getters['auth/isAuthenticated']
    },
    shouldChangeBodyBg () {
      return this.$route.name === 'ReportDesigner'
    },
  },
  watch: {
    isAuthenticated (val) {
      if (!val) {
        console.log('REMOVING!')
        window.localStorage.removeItem('kompass-vuex')
      } else if (val) {
        this.$store.dispatch('refreshCatsAndSubCats')
        this.$store.dispatch('refreshStandardActions')
      }
    },
  },
  mounted () {
    window.addEventListener('storage', this.handleLogout)
  },
  unmounted () {
    window.removeEventListener('storage', this.handleLogout)
  },
  methods: {
    handleLogout (e) {
      if (e.key === 'kompass-jwt' && e.newValue === null) {
        this.$store.dispatch('auth/logout')
        setTimeout(() => {
          location.href = '/login'
        }, 1000)
      }
    },
  },
}
</script>
<style>
html {
  font-size: 16px;
}
.content-wrapper {
  margin: 0 auto;
  width: 90%;
  max-width: 1800px;
}
.page-title {
  line-height: 70px;
  letter-spacing: -1px;
  font-size: 67px;
  margin-bottom: 45px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.8s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.list-item {
  transition: transform 0.5s ease;
}
.list-enter-from {
  opacity: 0;
}

.list-leave-to {
  display: none;
  visibility: hidden;
  /* transform: translateY(10px); */
}
.list-leave-active {
  position: absolute;
}
</style>
