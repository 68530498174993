<template>
  <div class="page relative">
    <transition name="fade">
      <Header v-if="showHeader" class="bg-white" />
    </transition>
    <div class="pt-52 mb-20 relative">
      <div class="content-wrapper relative">
        <transition name="fade">
          <h1 v-if="showTitle && title" class="page-title sm:space-x-8 flex flex-col sm:flex-row sm:items-end">
            <div class="flex" v-if="backButton">
              <ChevronLeftIcon class="w-12 lg:w-16 cursor-pointer" @click="$emit('back')"/>
            </div>
            <span>{{ title || '' }}</span>
            <span class="flex-grow pb-2 flex gap-4 sm:justify-between">
              <div>
                <slot name="main-controls"></slot>
              </div>
              <div>
                <slot name="secondary-controls"></slot>
              </div>
            </span>
          </h1>
        </transition>
        <div class="relative">
          <BaseContainerFillHeight uid="uid-page-layout" v-if="loading || error" :mb="150">
            <BaseLoader v-if="loading && !error" />
            <BaseError v-if="error" :errorMessage="error" />
          </BaseContainerFillHeight>
          <transition name="fade">
            <div v-if="!loading && !error && showContent" class="">
              <div>
                <slot name="content"></slot>
              </div>
            </div>
          </transition>
          <slot name="modals" v-if="!loading && !error"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/shared/header/Header'
import BaseLoader from '@/components/shared/BaseLoader.vue'
import BaseError from '@/components/shared/BaseError.vue'
import { ChevronLeftIcon } from '@heroicons/vue/solid'
import { sleep } from '@/composables/utils'
import BaseContainerFillHeight from '../shared/BaseContainerFillHeight.vue'

export default {
  name: 'PageLayout',
  components: {
    Header,
    BaseLoader,
    BaseError,
    ChevronLeftIcon,
    BaseContainerFillHeight,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    error: {
      type: [String, null],
      default: null,
    },
    autoFade: {
      type: Boolean,
      default: true,
    },
    backButton: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      showHeader: false,
      showTitle: false,
      showContent: false,
    }
  },
  watch: {
    loading (newVal) {
      if (!newVal && this.autoFade) {
        this.startFadeSequence()
      }
    },
  },
  methods: {
    async startFadeSequence () {
      console.log('startFadeSequence')
      this.showTitle = true
      await sleep(500)
      this.showHeader = true
      await sleep(700)
      this.showContent = !this.loading
      this.$emit('fade-complete')
    },
  },
  async mounted () {
    if (this.autoFade) {
      await this.startFadeSequence()
    }
  },
}
</script>

<style scoped>
/* You can add any specific styles here */
</style>
