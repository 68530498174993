import feathersClient, { makeServicePlugin, BaseModel } from '../../feathers-client'

class VoteTypes extends BaseModel {
  // Required for $FeathersVuex plugin to work after production transpile.

  static modelName = 'VoteTypes'
  // Define default properties here
  static instanceDefaults () {
    return {
      title: '',
    }
  }
}
const servicePath = 'vote-types'
const servicePlugin = makeServicePlugin({
  Model: VoteTypes,
  service: feathersClient.service(servicePath),
  servicePath,
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
})

export default servicePlugin
