<template>
  <BaseModal
    :modelValue="modelValue"
    :size="'lg'"
    @update:modelValue="updateModelValue"
    @cancel="closeModal"
  >
    <template v-slot:title>
      {{ $t('Projects.New project popup.Title') }}
    </template>
    <form @submit.prevent="emitCreateProject" data-cy="project-creation-form" class="px-4 flex flex-col gap-6">
      <template v-if="sections[currentSection] === 'info'">
        <ProjectSectionInfo
          v-model:title="project.title"
          v-model:description="project.description"
          v-model:projectNumber="project.projectNumber"
          v-model:folderId="project.folderId"
          v-model:folderTitle="project.folderTitle"
          v-model:countryId="project.countryId"
          :folderOptions="folderOptions"
          :countryOptions="countryOptions"
        />
      </template>
      <template v-if="sections[currentSection] === 'frameworks'">
        <ProjectSectionFrameworks
          :allowedFrameworks="allowedFrameworks"
          :selectedFrameworks="project.frameworkIds"
          @selectedFrameworksUpdated="updateSelectedFrameworks"
        />
      </template>
      <template v-if="sections[currentSection] === 'actionCollections'">
        <ProjectSectionCollections
          :allowedCollections="actionCollectionOptions"
          :selectedCollection="project.collectionId"
          @selectedCollectionUpdated="updateSelectedActionCollections"
        />
      </template>
      <template v-if="sections[currentSection] === 'questionCollections'">
        <ProjectSectionCollections
          :allowedCollections="questionCollectionOptions"
          :selectedCollection="project.questionCollectionId"
          @selectedCollectionUpdated="updateSelectedQuestionCollections"
        />
      </template>
    </form>
    <BaseWizardActions
      :currentSectionIndex="currentSection"
      :maxSectionIndex="sections.length - 1"
      :labels="{
        previous: $t('Projects.New project popup.Form_action_previous'),
        next: $t('Projects.New project popup.Form_action_next'),
        create: $t('Projects.New project popup.Form_action_create'),
        cancel: $t('Projects.New project popup.Form_action_cancel'),
        skip: $t('Projects.New project popup.Form_action_skip'),
      }"
      @previous="previousSection"
      @next="nextSection"
      @create="$emit('create', project)"
      @cancel="closeModal"
      @skip="nextSection"
    />
    <template v-slot:actions><div></div></template>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/shared/BaseModal'
import getAvailableCountries from '@/composables/countries/getAvailableCountries'
import findActionCollections from '@/composables/actionCollections/findActionCollections'
import findQuestionCollections from '@/composables/questionCollections/findQuestionCollections'
import ProjectSectionInfo from '@/components/projects/ProjectSectionInfo.vue'
import ProjectSectionFrameworks from '@/components/projects/ProjectSectionFrameworks.vue'
import ProjectSectionCollections from './ProjectSectionCollections.vue'
import BaseWizardActions from '@/components/shared/BaseWizardActions.vue'

export default {
  components: {
    BaseModal,
    ProjectSectionInfo,
    ProjectSectionFrameworks,
    ProjectSectionCollections,
    BaseWizardActions,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['update:modelValue', 'create'],
  data () {
    return {
      showModal: false,
      currentSection: 0,
      allowedFrameworks: [],
      project: {
        title: '',
        description: '',
        projectNumber: null,
        folderId: null,
        folderTitle: '',
        countryId: null,
        frameworkIds: [],
        collectionId: null,
        questionCollectionId: null,
      },
      folderOptions: [
        { value: 'new', label: this.$t('Projects.New project popup.Label05') },
      ],
      countryOptions: [],
      addCollection: false,
    }
  },
  computed: {
    sections () {
      const sections = ['info']
      if (this.allowedFrameworks.length > 1) {
        sections.push('frameworks')
      }
      if (this.actionCollectionOptions.length > 0) {
        sections.push('actionCollections')
      }
      if (this.questionCollectionOptions.length > 1) {
        sections.push('questionCollections')
      }
      console.log('sections', sections)
      return sections
    },
    actionCollectionOptions () {
      const options = this.$store.getters['action-collections/list']
        .filter(collection => (
          collection.isEnabled &&
          this.project.frameworkIds.includes(collection.frameworkId)
        ))
        .map(collection => ({
          label: collection.title,
          value: collection.id,
        }))
      return options
    },
    questionCollectionOptions () {
      console.log('questionCollectionOptions computed', this.$store.getters['question-collections/list'])
      const options = this.$store.getters['question-collections/list']
        .filter(collection => this.project.frameworkIds.includes(collection.frameworkId))
        .map(collection => ({
          label: collection.title,
          value: collection.id,
        }))
      return options
    },
  },
  methods: {
    updateModelValue (val) {
      this.$emit('update:modelValue', val)
    },
    closeModal () {
      this.$emit('update:modelValue', false)
      // close()
    },
    updateSelectedFrameworks (val) {
      this.project.frameworkIds = val
    },
    updateSelectedActionCollections (val) {
      console.log('updateSelectedActionCollections', val)
      this.project.collectionId = val
    },
    updateSelectedQuestionCollections (val) {
      this.project.questionCollectionId = val
    },
    previousSection () {
      this.currentSection--
    },
    nextSection () {
      this.currentSection++
    },
  },
  watch: {
    'project.countryId': async function (newCountryId, oldCountryId) {
      if (newCountryId !== oldCountryId) {
        this.collectionId = null
        const collections = await findActionCollections(false, newCountryId)
        console.log('Country id changed, collections: ', collections)
        // Code to execute when countryId changes
      }
    },
    async modelValue (newValue) {
      if (newValue) {
        await this.$store.dispatch('compass-folder/find')

        const countries = await getAvailableCountries()
        const noCountryLabel = this.$t('Projects.New project popup.country_select_no_country')
        this.countryOptions = [
          { value: null, label: noCountryLabel },
          ...countries.map(country => ({ label: country.native, value: country.id })),
        ]

        const compassFolders = this.$store.getters['compass-folder/list']
        const newFolderLabel = this.$t('Projects.New project popup.Label05')
        this.folderOptions = [
          { value: 'new', label: newFolderLabel },
          ...compassFolders.map(folder => ({ value: folder.id, label: folder.title })),
        ]
        this.allowedFrameworks = this.$store.getters['frameworks/list']
        this.allowedQuestionCollections = this.$store.getters['question-collections/list']
        this.allowedActionCollections = this.$store.getters['action-collections/list']

        this.project = {
          title: '',
          description: '',
          projectNumber: null,
          folderId: null,
          folderTitle: '',
          countryId: null,
          collectionId: null,
          questionCollectionId: null,
          frameworkIds: [this.allowedFrameworks[0].id],
        }
        this.currentSection = 0
      }
    },
    addCollection (newValue) {
      if (newValue && this.actionCollectionOptions.length) {
        this.project.collectionId = this.actionCollectionOptions[0].value
      } else {
        this.project.collectionId = null
      }
    },
    questionCollectionOptions: {
      handler (newValue) {
        console.log('questionCollectionOptions handler', newValue)
        if (newValue.length > 0) {
          this.project.questionCollectionId = newValue[0].value
        }
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted () {
    await findActionCollections()
    await findQuestionCollections()
    if (this.questionCollectionOptions.length > 0) {
      this.project.questionCollectionId = this.questionCollectionOptions[0].value
    }
    // get all frameworks
    console.log('modal mounted')
  },
}
</script>

<style scoped>
button:not(:last-child) {
  margin-right: 1rem;
}
</style>
