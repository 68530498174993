import { store } from '@/store'
/**
 * Takes an organizationId and returns
 * all the countries that are available
 * to that organization
 * TODO: allowed countries should be saved and controlled
 * in db, not hardcoded
 * @param {Number} organizationId
 * @returns
 */
export default async () => {
  const allowedCountries = ['DK', 'SE', 'NO']
  const countries = await store.dispatch('countries/find')
  const availableCountries = countries.data
    .filter(country => allowedCountries.includes(country.code))
  return JSON.parse(JSON.stringify(availableCountries))
}

export const getCountryName = async (countryId) => {
  const countries = await store.dispatch('countries/find')
  const country = countries.data.find(c => c.id === countryId)
  return country ? country.native : ''
}
