import feathersClient, { makeServicePlugin, BaseModel } from '../../feathers-client'
class SubCategory extends BaseModel {
  // Required for $FeathersVuex plugin to work after production transpile.

  static modelName = 'SubCategory'
  // Define default properties here
  static instanceDefaults () {
    return {
      categoryId: null,
      frameworksCategoryId: null,
      isRequired: false,
    }
  }

  static setupInstance (data, { models }) {
    if (data.defaultTranslation) {
      data.defaultTranslation = new models.api.SubCategoryTranslation(data.defaultTranslation)
    }
    if (data.translations) {
      data.translations = data.translations.map(translation => new models.api.SubCategoryTranslation(translation))
    }
    return data
  }
}
const servicePath = 'sub-categories'
const servicePlugin = makeServicePlugin({
  Model: SubCategory,
  service: feathersClient.service(servicePath),
  servicePath,
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
})

export default servicePlugin
