import { store } from '../store'
// import { specialOtherSubcats } from '../data'

/**
 * Get the first sub-category that matches the frameworkId
 * This is used to set the default sub-category for an action
 * @param {*} action
 * @param {number} frameworkId
 * @returns
 */
const getDefaultSubCategoryId = (action, frameworkId) => {
  if (!frameworkId) {
    console.warn('No frameworkId provided when getting default sub-category ID, using default of 1')
    frameworkId = 1
  }
  const subCategory = action.subCategories.find(subCat => subCat.frameworkId === frameworkId)
  return subCategory ? subCategory.subCategoryId : null
}

const getCategoryId = (subCategoryId) => {
  const subCat = store.getters['sub-categories/get'](subCategoryId)
  return subCat ? subCat.categoryId : null
}
const getCategoryIdFromCompassSubCategoryId = (compassSubCategoryId) => {
  const compassSubCat = store.getters['compass-sub-categories/get'](compassSubCategoryId)
  if (!compassSubCat) return null
  if (compassSubCat.categoryId) return compassSubCat.categoryId
  if (compassSubCat.subCategoryId) return getCategoryId(compassSubCat.subCategoryId)
  return null
}
const getFirstSubCategory = (categoryId) => {
  const subs = store.getters['sub-categories/list'].filter(sub => sub.categoryId === categoryId)
  const firstSub = subs[0].id
  return firstSub
}
const getCompassSubCategoryId = (subCategoryId) => {
  const compassSubCat = store.getters['compass-sub-categories/list']
    .find(compassSubCat => compassSubCat.subCategoryId === subCategoryId)
  return compassSubCat ? compassSubCat.id : null
}
const getOtherCompassSubcategory = (categoryId) => {
  // returning the special "other" compass-sub-cat
  return store.getters['compass-sub-categories/list']
    .find(compassSubCat => {
      if (!compassSubCat.categoryId) {
        return getCategoryId(compassSubCat.subCategoryId) === categoryId && compassSubCat.isRequired
      }
      return compassSubCat.categoryId === categoryId && compassSubCat.isRequired
    })
}
const getCompassSubCategories = (categoryId) => {
  return store.getters['compass-sub-categories/list']
    .filter(item => {
      const itemCategoryId = item.categoryId || getCategoryId(item.subCategoryId)
      return itemCategoryId === categoryId
    })
    .map(item => ({ ...item, title: item.title || getSubCategoryTitle(item.subCategoryId) }))
    .sort((a, b) => a.subCategoryId - b.subCategoryId)
}

const getSubCategoryTitle = (subCategoryId) => {
  const subs = store.getters['sub-categories/list']
  const subIndex = subs.findIndex(sub => sub.id === subCategoryId)
  const sub = subs[subIndex]
  return sub ? sub.title : null
}
const getSubCategoryDescription = (subCategoryId) => {
  const subs = store.getters['sub-categories/list']
  const subIndex = subs.findIndex(sub => sub.id === subCategoryId)
  const sub = subs[subIndex]
  return sub ? sub.description : null
}

const getCategoryIcon = (categoryId) => {
  if (categoryId) {
    const categories = store.getters['categories/list']
    const category = categories[categories.findIndex(cat => Number(cat.id) === categoryId)]
    return category ? category.icon : ''
  } else {
    return ''
  }
}
const debounce = (func, timeout) => {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => { func.apply(this, args) }, timeout)
  }
}

const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

const getOffset = (el) => {
  const rect = el.getBoundingClientRect()
  return {
    x: rect.left + window.scrollX,
    y: rect.top + window.scrollY,
  }
}
const getHighestId = (storeModulName) => {
  const existingIds = store.getters[storeModulName + '/list'].map(item => Number(item.id))
  if (existingIds[0]) {
    existingIds.sort(function (a, b) {
      return b - a
    })
  }
  return existingIds[0] ? existingIds[0] : 0
}
const numberToTwoDigits = (int) => {
  if (int < 10) {
    return '0' + int
  } else return int + ''
}
const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? { r: parseInt(result[1], 16), g: parseInt(result[2], 16), b: parseInt(result[3], 16) }
    : null
}
const isDeepEqual = (obj1, obj2) => {
  if (obj1 === obj2) return true
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 == null || obj2 == null) return false
  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)
  if (keys1.length !== keys2.length) return false
  for (const key of keys1) {
    if (!keys2.includes(key) || !isDeepEqual(obj1[key], obj2[key])) return false
  }
  return true
}

const getUpdatedKeys = (obj1, obj2) => {
  return Object.keys(obj1).filter(key => !isDeepEqual(obj1[key], obj2[key]))
}
const getUpdatedData = (obj1, obj2) => {
  return getUpdatedKeys(obj1, obj2).reduce((acc, key) => {
    acc[key] = obj1[key]
    return acc
  }, {})
}

const setupScrollListener = (containerElement, maxDistanceFromBottom, debounceDelay, callback) => {
  if (containerElement) {
    const handleScroll = () => {
      const hasScrolled = containerElement.scrollTop > 0
      const distanceFromBottom = containerElement.scrollHeight - containerElement.scrollTop - containerElement.clientHeight
      const isCloseToBottom = distanceFromBottom < maxDistanceFromBottom
      const isScrolledToBottom = hasScrolled && isCloseToBottom
      if (isScrolledToBottom) {
        callback()
      }
    }

    containerElement.addEventListener('scroll', debounce(handleScroll, debounceDelay))
  }
}

export {
  getUpdatedKeys,
  getUpdatedData,
  isDeepEqual,
  hexToRgb,
  getCategoryId,
  getSubCategoryTitle,
  getOtherCompassSubcategory,
  getCategoryIcon,
  sleep,
  debounce,
  getOffset,
  getHighestId,
  getFirstSubCategory,
  getDefaultSubCategoryId,
  numberToTwoDigits,
  getCompassSubCategoryId,
  getSubCategoryDescription,
  getCategoryIdFromCompassSubCategoryId,
  setupScrollListener,
  getCompassSubCategories,
}
